import * as yup from "yup";

export const getEmailSchema = (t: (key: string) => string) =>
	yup.object({
		email: yup.string().required(t("emailRequired")).email(t("emailNotValid")),
	});

export const getPasswordSchema = (t: (key: string) => string) =>
	yup.object({
		password: yup
			.string()
			.required(t("newPasswordRequired"))
			.min(8, t("passwordTooShort"))
			.matches(/[0-9]/, t("passwordNumberRequired"))
			.matches(/[a-zA-Z]/, t("passwordLetterRequired"))
			.matches(/(?=.*[^\da-zA-Z])/, t("passwordSpecialCharacterRequired")),
	});

export const getConfirmPasswordSchema = (t: (key: string) => string) =>
	yup.object({
		password: yup.string().required(),
		verifyPassword: yup
			.string()
			.required(t("confirmPasswordRequired"))
			.oneOf([yup.ref("password"), null], t("errorNonMatchingPassword")),
	});

export const getDateOfBirthSchema = (t: (key: string) => string) =>
	yup.object({
		dateOfBirth: yup
			.string()
			.required(t("dateOfBirthRequired"))
			.matches(/^\d{4}-\d{2}-\d{2}$/, t("dateOfBirthFormat"))
			.test("is-valid-date", t("dateOfBirthInvalid"), (value) => {
				if (!value) return false;
				const date = new Date(value);
				const isValid = !isNaN(date.getTime());
				return isValid;
			})
			.test("is-old-enough", t("dateOfBirthNotWithinRange"), (value) => {
				if (!value) return false;
				const today = new Date();
				const birthDate = new Date(value);
				let age = today.getFullYear() - birthDate.getFullYear();
				const m = today.getMonth() - birthDate.getMonth();
				if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
					age--;
				}
				return age >= 18 && age <= 150;
			}),
	});
