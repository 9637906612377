import { Link } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import {
	AcademicDegree,
	FieldOfStudy,
	ImageType,
	LanguageNiveau,
	UserRoles,
	YearsOfExperience,
} from "../../config/Types/GeneralEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { checkRole, getName } from "../../core/claimFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import {
	aboutMeDTO,
	certificateDTO,
	educationDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	userInformationDTO,
	workExperienceDTO,
} from "../../core/dto/user.models";
import { enumToString } from "../../core/helperConst";
import { formatDate } from "../../pages/MyCV/MyCV";
import { MatchingBaseDetailCard } from "../myProfile/MatchingBaseDetailCard";
interface props {
	userId: string | undefined;
	userInformation: userInformationDTO | undefined;
	backgroundImage: string;
	profilePicture: string | undefined;
	userDetail: userDetailsDTO;
	dreamJobDetail: internalJobDTO;
	aboutMeDetail: aboutMeDTO;
	educationDetail: educationDTO[];
	workExperienceDetail: workExperienceDTO[];
	skillsDetail: skillDTO[];
	languageSkillsDetail: languageSkillDTO[];
	certificatesDetail: certificateDTO[];
	images1: Record<ImageType, string>;
	generateCvRef: React.RefObject<HTMLDivElement>;
}

export const PdfGenerator = ({
	aboutMeDetail,
	backgroundImage,
	certificatesDetail,
	dreamJobDetail,
	educationDetail,
	languageSkillsDetail,
	skillsDetail,
	userDetail,
	userId,
	userInformation,
	workExperienceDetail,
	images1,
	profilePicture,
	generateCvRef,
}: props) => {
	const { claims } = useContext(AuthenticationContext);
	const { t } = useTranslation();

	return (
		<div
			className="pdf-wrapper"
			style={{ maxWidth: "1100px", minWidth: "1100px", overflow: "scroll" }}
			ref={generateCvRef}
		>
			{/* left start */}
			<div className="left">
				{/* user info start  */}
				<div className="user-info card">
					<div
						className="thumbnail"
						style={{
							backgroundImage: `url(${
								userId
									? backgroundImage || images1[ImageType.HeaderImage]
									: images1[ImageType.HeaderImage]
							})`,
						}}
					></div>
					{/* content start */}
					<div className="content">
						<img
							src={userId ? profilePicture : images1[ImageType.ProfileImage]}
							crossOrigin="anonymous"
							alt={getName(claims)}
							className={userInformation?.isAnonymized ? "blurContent" : ""}
						/>
						{/* user details start */}
						<div className="user-details">
							<div className="top">
								<div
									className={`user-name ${
										userInformation?.isAnonymized ? "blurContent" : ""
									}`}
								>
									{userId ? (
										<h4>{userInformation?.name ?? ""}</h4>
									) : (
										<h4>{`${userDetail.firstName ?? ""} ${userDetail.lastName ?? ""}`}</h4>
									)}

									<p>
										{userId
											? enumToString(UserRoles, userInformation?.role)
											: checkRole(claims, UserRoles.Admin)
												? t("administrator")
												: checkRole(claims, UserRoles.Recrewter)
													? t("company")
													: checkRole(claims, UserRoles.Employee)
														? t("professional")
														: ""}
									</p>
									{userDetail?.nationality && userDetail?.state && (
										<span className="location">
											<img src={images.Location} alt="Location" />
											<span>
												{enumToString(State, userDetail?.state) !== "None"
													? `${t(enumToString(State, userDetail?.state))}, `
													: ""}
												{t(
													enumToString(Country, Number(userDetail?.nationality))
												)}
											</span>
										</span>
									)}
								</div>
							</div>
						</div>
						{/* user details start */}
					</div>
					{/* content end */}
				</div>
				{/* user info end  */}

				{/* About Me start */}
				<div className="job-description card" id="aboutMe">
					<h6>{t("aboutMe")}</h6>
					<p>{aboutMeDetail.aboutMe}</p>
				</div>
				{/* About Me end */}

				{/* Dream job desc start */}
				<div className="job-description card" id="aboutMe">
					<h6>{t("dreamJobDescription")}</h6>
					<p>{dreamJobDetail.description}</p>
				</div>
				{/* Dream job desc end */}

				{/* education start */}
				<div className="education card" id="education">
					<h6>{t("Education")}</h6>
					<ul>
						{educationDetail.map((education) => (
							<li key={education.id}>
								<div className="icon">
									<img src={images.Education} alt="Education" />
								</div>
								<div className="details">
									<p>
										{t(
											enumToString(
												AcademicDegree,
												education.academicDegree ?? 0
											)
										)}
									</p>
									<span>{`${education.institution} ${
										education.fieldOfStudy
											? `| ${t(
													enumToString(
														FieldOfStudy,
														education.fieldOfStudy ?? 0
													)
												)}`
											: ""
									}`}</span>
									<small>{`${formatDate(
										education.startDate
									)} - ${formatDate(education.endDate)} ${education.address ? `| ${education.address}` : ""}`}</small>
								</div>
							</li>
						))}
					</ul>
				</div>
				{/* education end */}

				{/* professional experience start */}
				<div className="professional-experience card" id="experience">
					<h6>{t("professionalExperience")}</h6>
					<ul>
						{workExperienceDetail.map((workExperience) => (
							<li key={workExperience.id}>
								<div className="icon">
									<img src={images.JobsWhite} alt="Jobs" />
								</div>
								<div className="details">
									<p>{workExperience.jobTitle}</p>
									<span>{workExperience.company}</span>
									<small>{`${formatDate(
										workExperience.startDate
									)} - ${formatDate(workExperience.endDate)} ${workExperience.address ? `| ${workExperience.address}` : ""}`}</small>
								</div>
							</li>
						))}
					</ul>
				</div>
				{/* professional experience end */}

				<div className="professional-experience card">
					<h6>{t("languageSkills")}</h6>
					<ul>
						{languageSkillsDetail.map((skill) => (
							<li key={skill.id}>
								<div className="icon">
									<img src={images.LanguageSkillWhite} alt="User" />
								</div>
								<div>
									<p>{skill.language}</p>
									<span>
										{`${t(enumToString(LanguageNiveau, skill.niveau) || "")}`}
									</span>
								</div>
							</li>
						))}
					</ul>
				</div>
				<div className="professional-experience card">
					<h6>{t("skills")}</h6>
					<ul>
						{skillsDetail.map((skill) => (
							<li key={skill.id}>
								<div className="icon">
									<img src={images.SkillWhite} alt="User" />
								</div>
								<div>
									<p>{skill.name}</p>
									<span>
										{`${t(enumToString(YearsOfExperience, skill.years) || "")}`}
									</span>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
			{/* left end */}
			{/* right start */}
			<div className="right">
				<MatchingBaseDetailCard
					title="myDreamJob"
					jobProperties={dreamJobDetail}
				/>
				<div className="card personal-information">
					<h6>{t("personalInformation")}</h6>
					{userId ? (
						<ul className={userInformation?.isAnonymized ? "blurContent" : ""}>
							<li>
								<div className="icon">
									<img src={images.MyProfileActive} alt="User" />
								</div>
								<span>
									<small>{t("fullName")}</small>
									<span>{`${userInformation?.name}`}</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Email} alt="Email" />
								</div>
								<span>
									<small>{t("email")}</small>
									<span>
										{userInformation?.email ? (
											<Link
												href={`mailto:${userInformation.email}`}
												target="_blank"
											>
												{userInformation.email}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Call} alt="Call" />
								</div>
								<span>
									<small>{t("phoneNumber")}</small>
									<span>
										{userInformation?.phoneNumber
											? userInformation.phoneNumber
											: "-"}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Call} alt="Call" />
								</div>
								<span>
									<small>{t("mobileNumber")}</small>
									<span>
										{userInformation?.mobileNumber
											? userInformation.mobileNumber
											: "-"}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.LinkedinPrimary} alt="Linkedin" />
								</div>
								<span>
									<small>{t("linkedin")}</small>
									<span>
										{userInformation?.linkedIn ? (
											<Link target="_blank" href={userInformation?.linkedIn}>
												{userInformation.linkedIn}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.JobsActive} alt="Job" />
								</div>
								<span>
									<small>{t("xing")}</small>
									<span>
										{userInformation?.xing ? (
											<Link target="_blank" href={userInformation?.xing}>
												{userInformation.xing}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Globe} alt="Globe" />
								</div>
								<span>
									<small>{t("website")}</small>
									<span>
										{userInformation?.website ? (
											<Link target="_blank" href={userInformation?.website}>
												{userInformation.website}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
						</ul>
					) : (
						<ul>
							<li>
								<div className="icon">
									<img src={images.MyProfileActive} alt="User" />
								</div>
								<span>
									<small>{t("fullName")}</small>
									<span>{`${userDetail?.firstName} ${userDetail?.lastName}`}</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Email} alt="Email" />
								</div>
								<span>
									<small>{t("email")}</small>
									<span>
										{userDetail?.email ? (
											<Link href={`mailto:${userDetail.email}`} target="_blank">
												{userDetail.email}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Call} alt="Call" />
								</div>
								<span>
									<small>{t("phoneNumber")}</small>
									<span>
										{userDetail.phoneNumber ? userDetail.phoneNumber : "-"}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Call} alt="Call" />
								</div>
								<span>
									<small>{t("mobileNumber")}</small>
									<span>
										{userDetail.mobileNumber ? userDetail.mobileNumber : "-"}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.LinkedinPrimary} alt="Linkedin" />
								</div>
								<span>
									<small>{t("linkedin")}</small>
									<span>
										{userDetail?.linkedIn ? (
											<Link target="_blank" href={userDetail?.linkedIn}>
												{userDetail.linkedIn}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.JobsActive} alt="Job" />
								</div>
								<span>
									<small>{t("xing")}</small>
									<span>
										{userDetail?.xing ? (
											<Link target="_blank" href={userDetail?.xing}>
												{userDetail.xing}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
							<li>
								<div className="icon">
									<img src={images.Globe} alt="Globe" />
								</div>
								<span>
									<small>{t("website")}</small>
									<span>
										{userDetail?.website ? (
											<Link target="_blank" href={userDetail?.website}>
												{userDetail.website}
											</Link>
										) : (
											"-"
										)}
									</span>
								</span>
							</li>
						</ul>
					)}
				</div>

				<div className="card">
					<h6 className="pb-0">{t("certificates")}</h6>
					<ul>
						{certificatesDetail.map((certificate) => (
							<li
								key={certificate.id}
								className={userInformation?.isAnonymized ? "blurContent" : ""}
							>
								<div className="icon">
									<img src={images.Certificate} alt="User" />
								</div>
								<span>
									<small>{certificate.title}</small>
									<span>{`${formatDate(
										certificate.startDate
									)} - ${formatDate(certificate.endDate)}`}</span>
								</span>
							</li>
						))}
					</ul>
				</div>
			</div>
			{/* right end */}
		</div>
	);
};
