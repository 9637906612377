import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import YupPassword from "yup-password";
import { changePasswordAPI } from "../../core/apiFunctions";
import useFeedback from "../../utils/useFeedback";
import FormikTextField from "../common/FormikTextField";
import DisplayErrors from "../common/DisplayErrors";
import { Button } from "@mui/material";
import ModalContent from "../../utils/ModalContent";
YupPassword(yup);

interface PasswordChangeContentProps {
	handleClose: () => void;
	isOpen: boolean;
}

export default function PasswordChangeContent({
	handleClose,
	isOpen,
}: PasswordChangeContentProps) {
	const { t } = useTranslation();
	const { showSuccess } = useFeedback();
	const [errors, setErrors] = useState<string[]>([]);

	const validationSchema = yup.object({
		oldPassword: yup.string().required(t("oldPasswordRequired")),

		// At least 8 characters, at least one letter, one number and one special character
		newPassword: yup
			.string()
			.required(t("newPasswordRequired"))
			.min(8, t("passwordTooShort"))
			.matches(/[0-9]/, t("passwordNumberRequired"))
			.matches(/[a-zA-Z]/, t("passwordLetterRequired"))
			.matches(/(?=.*[^\da-zA-Z])/, t("passwordSpecialCharacterRequired")),

		confirmNewPassword: yup
			.string()
			.required(t("confirmPasswordRequired"))
			.oneOf([yup.ref("newPassword"), null], t("errorNonMatchingPassword")),
	});

	async function submitPasswordChange(values: {
		oldPassword: string;
		newPassword: string;
		confirmNewPassword: string;
	}) {
		try {
			const response = await changePasswordAPI({
				currentPassword: values.oldPassword,
				newPassword: values.newPassword,
			});
			if (response.status === 200) {
				handleClose();
				showSuccess(t("passwordChanged"));
			} else {
				setErrors(response.data);
			}
		} catch (error: any) {
			if (error && error.response) {
				setErrors(error.response.data);
			}
		}
	}

	return (
		<ModalContent
			title={t("resetPassword")}
			size="sm"
			show={isOpen}
			onClose={handleClose}
		>
			<Formik
				initialValues={{
					oldPassword: "",
					newPassword: "",
					confirmNewPassword: "",
				}}
				onSubmit={async (values) => {
					await submitPasswordChange(values);
				}}
				validationSchema={validationSchema}
				validateOnBlur={false}
			>
				{() => (
					<Form>
						<FormikTextField
							displayName={t("oldPassword")}
							field="oldPassword"
							type="password"
							autoFocus={true}
						/>
						<FormikTextField
							displayName={t("newPassword")}
							field="newPassword"
							type="password"
						/>
						<FormikTextField
							displayName={t("verifyPassword")}
							field="confirmNewPassword"
							type="password"
						/>
						<DisplayErrors errors={errors} />
						<div
							className="buttons"
							style={{
								display: "flex",
								gap: "20px",
								padding: "16px 8px 8px 0",
								justifyContent: "flex-end",
							}}
						>
							<Button variant="contained" type="submit">
								{t("changePassword")}
							</Button>
							<Button variant="outlined" onClick={handleClose}>
								{t("cancel")}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</ModalContent>
	);
}
