import { FormControl, IconButton, OutlinedInput } from "@mui/material";

import React from "react";

import { useTranslation } from "react-i18next";

export default function TextField({
	type = "text",
	formFloating = false,
	isMandatory = false,
	disabled = false,
	...props
}: textFieldProps) {
	const [fieldText, setFieldText] = React.useState<string>();
	const { t } = useTranslation();

	React.useEffect(() => {
		setFieldText(props.value);
	}, [props.value]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			props.onChange(e);
			setFieldText(e.target.value);
		}
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (props.onBlur) {
			setFieldText(e.target.value);
			props.onBlur(e);
		}
	};

	const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
		if (props.onClick) {
			props.onClick(e);
		}
	};

	return (
		<FormControl className={`${props.className} ${disabled ? "disabled" : ""}`}>
			<label htmlFor={props.field} className="floating-label">
				{props.displayName ? t(props.displayName) : ""}
			</label>
			<OutlinedInput
				type={type}
				value={fieldText}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				onClick={handleOnClick}
				multiline={props.isMultiline}
				rows={props.rows}
				className={`form-control ${type === "password" ? "with-icon" : ""}`}
				id={props.field}
				name={props.field}
				placeholder={props.placeholder}
				style={{ height: props.height ? props.height : "" }}
				inputProps={{
					disabled: disabled,
				}}
				error={!!props.validationMessage}
				endAdornment={
					props.icon ? (
						<IconButton aria-label="toggle password visibility" edge="end">
							<img src={props.icon} alt={props.iconAlt} />
						</IconButton>
					) : undefined
				}
			/>

			{props.validationMessage && (
				<div className="text-danger" style={{ color: "red" }}>
					{t(props.validationMessage)}
				</div>
			)}
		</FormControl>
	);
}

interface textFieldProps {
	field: string;
	as?: "textarea";
	height?: string;
	placeholder?: string;
	displayName?: string;
	isMultiline?: boolean;
	rows?: number;
	icon?: string;
	iconAlt?: string;
	formFloating?: boolean;
	type?: "text" | "password" | "date";
	class?: string;
	value?: string;
	disabled?: boolean;
	onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
	onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
	validationMessage?: string;
	isMandatory?: boolean;
	onClick?(event: React.MouseEvent<HTMLInputElement>): void;
	className?: string;
}
