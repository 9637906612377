export enum Claims {
	NameIdentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
	UserRole = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
	Email = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
	Name = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
	Surname = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
	Licence = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/licence",
	AuthorizationDecision = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision",
	UserProfileComplete = "isUserPublic",
}
