import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	FormControl,
	IconButton,
	OutlinedInput,
	SelectChangeEvent,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import images from "../../assets/images";
import {
	FeedbackMode,
	Title,
	UserRoles,
} from "../../config/Types/GeneralEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import {
	existsUserAPI,
	isEmailConfirmed,
	registerAPI,
} from "../../core/apiFunctions";
import { userDetailsDTO } from "../../core/dto/user.models";
import useFeedback from "../../utils/useFeedback";
import {
	getConfirmPasswordSchema,
	getDateOfBirthSchema,
	getEmailSchema,
	getPasswordSchema,
} from "../../utils/validationSchemas";
import dayjs from "dayjs";
import DropdownField from "../common/DropdownField";

enum Roles {
	None = 0,
	Recrewter = 1,
	Employee = 2,
}

type Props = {
	selectedRole: Roles;
	setSelectedRole: React.Dispatch<React.SetStateAction<Roles>>;
	currentQuestionIndex: number;
	showChat: boolean;
	setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
	setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
	expanded: boolean;
	setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RegisterChatBot = ({
	selectedRole,
	setSelectedRole,
	currentQuestionIndex,
	showChat,
	setShowChat,
	setCurrentQuestionIndex,
	expanded,
	setExpanded,
}: Props) => {
	enum YesNo {
		No = 1,
		Yes = 2,
	}

	enum Roles {
		None = 0,
		Recrewter = 1,
		Employee = 2,
	}
	const [conversation, setConversation] = useState<Message[]>([]);
	const [userInput, setUserInput] = useState<string>("");
	const [isTyping, setIsTyping] = useState(false);

	const [welcomeMessageShown, setWelcomeMessageShown] = useState(false);
	const [showChatBotWelcomeMsg, setShowChatBotWelcomeMsg] = useState(true);
	// Not stored in UserDetails. Its an exceptional case.
	const residencePermitOptionRef = useRef<number>(0);

	const [userCredentials, setUserCredentials] = useState({
		email: "",
		password: "",
		verifyPassword: "",
	});
	const [userDetails, setUserDetails] = useState<userDetailsDTO>({
		id: "",
		title: Title.None,
		isPublic: true,
		notificationsEnabled: true,
		firstName: "",
		lastName: "",
		dateOfBirth: "",
		nationality: Country.None,
		residencePermit: "",
		state: State.None,
		email: "",
		phoneNumber: "",
		mobileNumber: "",
		linkedIn: "",
		xing: "",
		website: "",
		createdDate: new Date(),
	});
	const [companyDetails, setCompanyDetails] = useState({
		id: "",
		name: "",
		description: "",
		address: "",
		city: "",
		postcode: "",
		state: State.None,
		country: Country.None,
		uid: "",
		email: "",
		phoneNumber: "",
		linkedIn: "",
		xing: "",
		website: "",
		logo: "",
		header: "",
		youTubeUrl: "",
		createdDate: new Date(),
	});
	const { t } = useTranslation();
	const { showError, showSuccess } = useFeedback();
	const handleChatbotOpen = () => {
		if (selectedRole !== Roles.None) {
			setCurrentQuestionIndex(0);
			setExpanded((prevExpanded) => !prevExpanded);
			setShowChat(true);
		} else {
			showError(t("missingRole"), FeedbackMode.Error, 5000);
		}
	};

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const navigate = useNavigate();

	const updateUserData = (key: string, value: string) => {
		// enum
		const numberKeys = ["nationality", "country", "state"];
		// common keys
		const commonKeys = [
			"phoneNumber",
			"state",
			"country",
			"linkedIn",
			"xing",
			"website",
		];

		if (key === "email") {
			if (currentQuestionIndex < userDetailsAndCredentialsQuestions.length) {
				setUserCredentials((prev) => ({ ...prev, email: value }));
				setUserDetails((prev) => ({ ...prev, email: value }));
			} else {
				// If we are past the user questions, it's a company email
				setCompanyDetails((prev) => ({ ...prev, email: value }));
			}
			return;
		}

		if (key === "state") {
			if (currentQuestionIndex < userDetailsAndCredentialsQuestions.length) {
				setUserDetails((prev) => ({ ...prev, state: Number(value) }));
			} else {
				// If we are past the user questions, it's a company state
				setCompanyDetails((prev) => ({ ...prev, state: Number(value) }));
			}
			return;
		}

		if (commonKeys.includes(key)) {
			if (key in userCredentials) {
				setUserCredentials((prev) => ({ ...prev, [key]: value }));
			}
			if (key in userDetails) {
				setUserDetails((prev) => ({
					...prev,
					[key]: numberKeys.includes(key) ? Number(value) : value,
				}));
			}
			if (key in companyDetails) {
				setCompanyDetails((prev) => ({
					...prev,
					[key]: numberKeys.includes(key) ? Number(value) : value,
				}));
			}
			return;
		}

		// Handle password-related updates
		if (["password", "verifyPassword"].includes(key)) {
			setUserCredentials((prev) => ({ ...prev, [key]: value }));
			return;
		}

		// Handle userDetails updates for other specific keys
		if (key in userDetails) {
			setUserDetails((prev) => ({
				...prev,
				[key]: numberKeys.includes(key) ? Number(value) : value,
			}));
			return;
		}

		// Handle companyDetails updates for other specific keys
		if (key in companyDetails) {
			setCompanyDetails((prev) => ({
				...prev,
				[key]: numberKeys.includes(key) ? Number(value) : value,
			}));
			return;
		}

		// Special handling for residencePermitOption
		if (key === "residencePermitOption") {
			residencePermitOptionRef.current = Number(value);
			setUserDetails((prev) => ({
				...prev,
				residencePermit: "no residence permit",
			}));
			setUserInput("");
		}
	};

	const chatBoxRef = useRef<HTMLDivElement>(null);

	// User Details and Credentials Questions
	const userDetailsAndCredentialsQuestions: ChatbotQuestion[] = useMemo(
		() => [
			{
				key: "email",
				message: t("bot.startLoginEmail"),
				validation: getEmailSchema(t),
				type: "text",
			},
			{
				key: "password",
				message: t("bot.password"),
				validation: getPasswordSchema(t),
				type: "password",
			},
			{
				key: "verifyPassword",
				message: t("bot.confirmPassword"),
				validation: getConfirmPasswordSchema(t),
				type: "password",
			},
			{
				key: "firstName",
				message: t("bot.firstName"),
				validation: yup.object({
					firstName: yup.string().required(t("FirstNameRequired")),
				}),
				type: "text",
			},
			{
				key: "lastName",
				message: t("bot.lastName", { firstName: userDetails.firstName }),
				validation: yup.object({
					lastName: yup.string().required(t("LastNameRequired")),
				}),
				type: "text",
			},
			{
				key: "dateOfBirth",
				message: t("bot.birthday"),
				validation: getDateOfBirthSchema(t),
				type: "date",
			},
			{
				key: "nationality",
				message: t("bot.nationality"),
				validation: yup.object({
					nationality: yup.number().required(t("NationalityRequired")),
				}),
				type: "dropdown",
				enumType: Country,
			},
			{
				key: "state",
				message:
					userDetails.nationality === Country.Switzerland
						? t("bot.canton")
						: t("bot.state"),
				validation: yup.object({
					state: yup
						.number()
						.required(
							userDetails.nationality === Country.Switzerland
								? t("CantonRequired")
								: t("StateRequired")
						),
				}),
				type: "dropdown",
				enumType: State,
				condition: (answers: any) => {
					const nationality = answers["nationality"];
					return [
						Country.Austria,
						Country.Germany,
						Country.Switzerland,
					].includes(nationality);
				},
				dropdownProps: {
					index:
						userDetails.nationality === Country.Germany
							? 8400
							: userDetails.nationality === Country.Austria
								? 1500
								: 21600, // 21600 for Switzerland (Cantons)
					indexSize:
						userDetails.nationality === Country.Germany
							? 17
							: userDetails.nationality === Country.Austria
								? 10
								: 27, // 27 Cantons for Switzerland
				},
			},
			{
				key: "residencePermitOption",
				message: t("bot.residencePermitQuestion"),
				type: "dropdown",
				enumType: YesNo,
				condition: (answers: any) => {
					const nationality = answers["nationality"];
					return ![Country.Switzerland].includes(nationality);
				},
			},
			{
				key: "residencePermit",
				message: t("bot.residencePermit"),
				validation: yup.object({
					residencePermit: yup.string().required(t("ResidencePermitRequired")),
				}),
				type: "text",
				condition: (answers: any) => {
					const nationality = userDetails.nationality;
					return (
						nationality !== Country.Switzerland &&
						residencePermitOptionRef.current === YesNo.Yes
					);
				},
			},
			{
				key: "mobileNumber",
				message: t("bot.mobileNumber"),
				validation: yup.object({
					mobileNumber: yup.string().required(t("MobileNumberRequired")),
				}),
				type: "text",
			},
			// Additional questions can be added here
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, userDetails.firstName, userDetails.nationality]
	);

	// Company Details Questions
	const companyDetailsQuestions: ChatbotQuestion[] = useMemo(
		() => [
			{
				key: "recrewterIntro",
				message: t("bot.recrewterIntro"),
				autoNext: true,
			},
			{
				key: "name",
				message: t("bot.companyName"),
				validation: yup.object({
					name: yup.string().required(t("CompanyNameRequired")),
				}),
				type: "text",
			},
			{
				key: "description",
				message: t("bot.companyDescription"),
				validation: yup.object({
					description: yup.string().required(t("companyDescriptionRequired")),
				}),
				type: "text",
			},
			{
				key: "uid",
				message: t("bot.uid"),
				validation: yup.object({
					uid: yup.string().required(t("companyUidRequired")),
				}),
				type: "text",
			},
			{
				key: "country",
				message: t("bot.companyCountry"),
				validation: yup.object({
					country: yup.number().required(t("CompanyCountryRequired")),
				}),
				type: "dropdown",
				enumType: Country,
			},
			{
				key: "state",
				message:
					companyDetails.country === Country.Switzerland
						? t("bot.companyCanton")
						: t("bot.companyState"),
				validation: yup.object({
					state: yup
						.number()
						.required(
							companyDetails.country === Country.Switzerland
								? t("CantonRequired")
								: t("StateRequired")
						),
				}),
				type: "dropdown",
				enumType: State,
				condition: (answers: any) => {
					const country = answers["country"];
					return [
						Country.Austria,
						Country.Germany,
						Country.Switzerland,
					].includes(country);
				},
				dropdownProps: {
					index:
						companyDetails.country === Country.Germany
							? 8400
							: companyDetails.country === Country.Austria
								? 1500
								: 21600, // 21600 for Switzerland (Cantons)
					indexSize:
						companyDetails.country === Country.Germany
							? 17
							: companyDetails.country === Country.Austria
								? 10
								: 27, // 27 Cantons for Switzerland
				},
			},
			{
				key: "address",
				message: t("bot.companyAddress"),
				validation: yup.object({
					address: yup.string().required(t("CompanyAddressRequired")),
				}),
				type: "text",
			},
			{
				key: "city",
				message: t("bot.companyCity"),
				validation: yup.object({
					city: yup.string().required(t("CompanyCityRequired")),
				}),
				type: "text",
			},
			{
				key: "postcode",
				message: t("bot.companyPostcode"),
				validation: yup.object({
					postcode: yup.string().required(t("CompanyPostcodeRequired")),
				}),
				type: "text",
			},
			{
				key: "email",
				message: t("bot.companyEmail"),
				validation: getEmailSchema(t),
				type: "text",
			},
			{
				key: "phoneNumber",
				message: t("bot.companyPhoneNumber"),
				validation: yup.object({
					phoneNumber: yup.string().required(t("CompanyPhoneNumberRequired")),
				}),
				type: "text",
			},
			// Additional required questions can be added here
		],
		[t, companyDetails.country]
	);

	// Chatbot Questions
	const chatbotQuestions = useMemo(() => {
		if (selectedRole === Roles.Employee) {
			return userDetailsAndCredentialsQuestions;
		} else if (selectedRole === Roles.Recrewter) {
			return [
				...userDetailsAndCredentialsQuestions,
				...companyDetailsQuestions,
			];
		} else {
			return [];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedRole,
		userDetailsAndCredentialsQuestions,
		companyDetailsQuestions,
	]);

	const handleSend = async () => {
		if (!userInput || !userInput.toString().trim()) return;

		const currentQuestion = chatbotQuestions[currentQuestionIndex];
		const key = currentQuestion.key;

		let displayMessage = userInput;

		if (currentQuestion.type === "dropdown" && currentQuestion.enumType) {
			displayMessage =
				t(
					currentQuestion?.enumType[
						userInput as keyof typeof currentQuestion.enumType
					].toString() ?? "None"
				) ?? displayMessage;
		}

		if (currentQuestion.type === "password") {
			displayMessage = "•".repeat(userInput.length);
		}

		// Append user's message to conversation
		setConversation((prev) => [
			...prev,
			{ from: "user", message: displayMessage },
		]);

		// Validate input
		if (currentQuestion.validation) {
			try {
				let dataToValidate: any = { [key]: userInput };

				if (key === "verifyPassword") {
					dataToValidate = {
						password: userCredentials.password,
						verifyPassword: userInput,
					};
				}

				await currentQuestion.validation.validate(dataToValidate, {
					abortEarly: false,
				});
			} catch (error) {
				if (error instanceof yup.ValidationError) {
					const errorMessage = error.errors[0];
					setConversation((prev) => [
						...prev,
						{ from: "bot", message: errorMessage },
					]);

					if (key === "verifyPassword") {
						// Reset password and confirmation if they don't match
						setUserCredentials((prev) => ({
							...prev,
							password: "",
							verifyPassword: "",
						}));

						setConversation((prev) => [
							...prev,
							{ from: "bot", message: t("bot.restartPasswordProcess") },
						]);

						setCurrentQuestionIndex(
							chatbotQuestions.findIndex((q) => q.key === "password")
						);
					}
					// else {
					//   setConversation((prev) => [
					//     ...prev,
					//     { from: "bot", message: errorMessage },
					//   ])
					// }
					setUserInput("");
					return;
				}
			}
		}

		// Check if user exists
		if (key === "email" && currentQuestionIndex === 0) {
			try {
				const response = await existsUserAPI(userInput);
				if (response.data.sso) {
					setUserInput("");
					setIsTyping(true);
					setTimeout(() => {
						setConversation((prev) => [
							...prev,
							{ from: "bot", message: t("ssoExists") },
						]);
						setIsTyping(false);
					}, 1000);
					navigate("/login");
					return;
				} else if (response.data.exists) {
					const isEmailVerified = await isEmailConfirmed(userInput);
					if (isEmailVerified) {
						navigate("/login", {
							state: { email: userInput, role: response.data.role },
						});
						return;
					} else {
						navigate("/verifyEmail");
						return;
					}
				}
				// If user does not exist, continue registration process
			} catch (error) {
				setConversation((prev) => [
					...prev,
					{
						from: "bot",
						message: "An error occurred. Please try again later.",
					},
				]);
				setUserInput("");
				return;
			}
		}

		// Save input
		updateUserData(key, userInput);

		// Move to next question
		setUserInput("");
		setCurrentQuestionIndex((prev) => prev + 1);
	};

	// Allow user to press Enter to send the message
	const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleSend();
		}
	};

	// Typing indicator from the bot
	const typingIndicator = (
		<div className="typing">
			<span></span>
			<span></span>
			<span></span>
		</div>
	);

	useEffect(() => {
		if (!showChat || currentQuestionIndex < 0) {
			return;
		}

		if (currentQuestionIndex === 0 && conversation.length === 0) {
			// Add initial welcome message
			setIsTyping(true);
			setTimeout(() => {
				setConversation((prev) => [
					...prev,
					{ from: "bot", message: t("bot.welcome") },
				]);
				setIsTyping(false);
				setWelcomeMessageShown(true);
			}, 1000);
			return;
		}

		// Check if all questions have been answered
		if (currentQuestionIndex >= chatbotQuestions.length) {
			// Function to handle credentials registration
			const handleChatDTOs = async () => {
				try {
					userDetails.nationality = userDetails.nationality.toString();

					const registerResponse = await registerAPI(
						userCredentials,
						selectedRole as unknown as UserRoles,
						userDetails,
						companyDetails
					);
					if (registerResponse.status !== 200) {
						showError(t("registerFailed"), FeedbackMode.Error, 5000);
						return false;
					}
					return true;
				} catch (error) {
					showError(t("registerFailed"), FeedbackMode.Error, 5000);
					return false;
				}
			};

			const handleRegistration = async () => {
				const credentialsSuccess = await handleChatDTOs();
				if (!credentialsSuccess) {
					return;
				}

				// If all operations succeeded
				showSuccess(t("registerSuccess"), FeedbackMode.Success, 5000);
				navigate("/verifyEmail");
			};

			handleRegistration();
			return;
		}

		const currentQuestion = chatbotQuestions[currentQuestionIndex];

		if (currentQuestion.autoNext) {
			setIsTyping(true);
			setTimeout(() => {
				setConversation((prev) => [
					...prev,
					{ from: "bot", message: currentQuestion.message },
				]);
				setIsTyping(false);
				setCurrentQuestionIndex((prev) => prev + 1);
			}, 1000);
			return;
		}

		// Check condition if question needs to be skipped
		if (
			currentQuestion.condition &&
			!currentQuestion.condition({ ...userDetails, ...companyDetails })
		) {
			setCurrentQuestionIndex((prev) => prev + 1);
			return;
		}

		setIsTyping(true);
		setTimeout(() => {
			setConversation((prev) => [
				...prev,
				{ from: "bot", message: currentQuestion.message },
			]);
			setIsTyping(false);
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentQuestionIndex,
		t,
		chatbotQuestions,
		userDetails,
		companyDetails,
		selectedRole,
		showChat,
		welcomeMessageShown,
		residencePermitOptionRef,
	]);

	// Scroll down to newest message
	useEffect(() => {
		chatBoxRef.current?.scrollTo(0, chatBoxRef.current.scrollHeight);
	}, [conversation]);

	const currentDate = new Date();

	return (
		<div className="chat-bot">
			<div className={`bot-icon ${showChat ? "displayNone" : ""}`}>
				<div
					className={`welcome-message ${
						!showChatBotWelcomeMsg ? "displayNone" : ""
					}`}
				>
					<span>
						<span>👋{t("welcome")},</span> {t("to")} {t("recrewter")}
					</span>
					<IconButton
						title="Close"
						onClick={() => setShowChatBotWelcomeMsg(false)}
					>
						<img src={images.Close} alt="Close" />
					</IconButton>
				</div>
				<Button
					variant="contained"
					title="Let's Chat"
					onClick={handleChatbotOpen}
				>
					<img src={images.Bot} alt="Bot" />
				</Button>
			</div>

			<Accordion
				expanded={expanded}
				onChange={handleExpansion}
				slotProps={{ transition: { timeout: 400 } }}
				sx={{
					opacity: showChat ? 1 : 0,
					visibility: showChat ? "visible" : "hidden",
				}}
			>
				<AccordionSummary
					aria-controls="panel1-content"
					expandIcon={<img src={images.ExpandArrow} alt="Down Arrow" />}
					id="panel1-header"
				>
					<img src={images.Bot} alt="Bot" />
					<span>
						<span>{t("bot")}</span>
						<small>{t("online")}</small>
					</span>
				</AccordionSummary>
				<AccordionDetails>
					<div className="chats" ref={chatBoxRef}>
						{conversation.map((messageObj, index) => (
							<div
								className={
									messageObj.from === "user" ? "user-message" : "bot-message"
								}
								key={`message-${index}`}
							>
								{messageObj.from !== "user" && (
									<div className="icon">
										<img src={images.Bot} alt="Bot" />
									</div>
								)}
								<div className="message">
									<p>{messageObj.message}</p>
								</div>
							</div>
						))}
						{isTyping && typingIndicator}
					</div>
					<div className="input-box">
						<IconButton title="Attach" className="attach">
							<img src={images.Attach} alt="Attach" />
						</IconButton>
						{chatbotQuestions[currentQuestionIndex]?.type === "dropdown" ? (
							<DropdownField
								field={chatbotQuestions[currentQuestionIndex].key}
								enumType={chatbotQuestions[currentQuestionIndex].enumType}
								index={
									chatbotQuestions[currentQuestionIndex].dropdownProps?.index
								}
								indexSize={
									chatbotQuestions[currentQuestionIndex].dropdownProps
										?.indexSize
								}
								selected={userInput}
								onChange={(e: SelectChangeEvent<string>) =>
									setUserInput(e.target.value)
								}
								isMandatory={true}
								autoFocus
							/>
						) : chatbotQuestions[currentQuestionIndex]?.type === "date" ? (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									format="DD-MM-YYYY"
									maxDate={dayjs(new Date())}
									minDate={dayjs(
										new Date().setFullYear(currentDate.getFullYear() - 150)
									)}
									value={userInput ? dayjs(userInput) : null}
									onChange={(v) =>
										setUserInput(v ? v.format("YYYY-MM-DD") : "")
									}
									autoFocus
								/>
							</LocalizationProvider>
						) : (
							<FormControl>
								<OutlinedInput
									id="Email"
									type={chatbotQuestions[currentQuestionIndex]?.type}
									placeholder={t("bot.typeAnswer")}
									value={userInput}
									onChange={(e) => setUserInput(e.target.value)}
									onKeyDown={handleInputKeyPress}
									autoFocus
								/>
							</FormControl>
						)}

						<IconButton className="send" title="Send" onClick={handleSend}>
							<img src={images.Send} alt="Send" />
						</IconButton>
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

interface Message {
	from: "user" | "bot";
	message: string;
}

interface ChatbotQuestion {
	key: string;
	message: string;
	validation?: yup.ObjectSchema<any>;
	type?: "text" | "password" | "date" | "dropdown";
	enumType?: any;
	condition?: (answers: any) => boolean;
	dropdownProps?: {
		index: number;
		indexSize: number;
	};
	autoNext?: boolean;
}
