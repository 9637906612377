import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Button } from "@mui/material";
import FormikTextField from "../common/FormikTextField";
import DisplayErrors from "../common/DisplayErrors";
import useFeedback from "../../utils/useFeedback";
import { setNewPasswordAPI } from "../../core/apiFunctions";
import ModalContent from "../../utils/ModalContent";
import { FeedbackMode } from "../../config/Types/GeneralEnumDefinitions";

interface ResetPasswordContentProps {
	isOpen: boolean;
	handleClose: () => void;
	token: string;
	email?: string;
}

const ResetPasswordContent: React.FC<ResetPasswordContentProps> = ({
	isOpen,
	handleClose,
	token,
	email,
}) => {
	const { t } = useTranslation();
	const [errors, setErrors] = useState<string[]>([]);
	const { showSuccess } = useFeedback();

	const validationSchema = yup.object({
		password: yup
			.string()
			.required(t("passwordRequired"))
			.min(8, t("passwordTooShort")),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password")], t("passwordsMustMatch"))
			.required(t("confirmPasswordRequired")),
	});

	const submitNewPassword = async (values: {
		password: string;
		confirmPassword: string;
	}) => {
		try {
			setErrors([]);
			// Prepare the passwordResetDTO object
			const passwordResetData = {
				email: email || "",
				token: token,
				newPassword: values.password,
			};
			const response = await setNewPasswordAPI(passwordResetData);
			if (response.status === 200) {
				handleClose();
				showSuccess(t("passwordResetSuccessful"), FeedbackMode.Success, 10000);
			} else {
				setErrors(response.data);
			}
		} catch (error: any) {
			if (error.response && error.response.data) {
				setErrors(error.response.data);
			} else {
				setErrors([t("requestFailed")]);
			}
		}
	};

	return (
		<ModalContent
			title={t("resetPassword")}
			size="sm"
			show={isOpen}
			onClose={handleClose}
		>
			<Formik
				initialValues={{ password: "", confirmPassword: "" }}
				onSubmit={submitNewPassword}
				validationSchema={validationSchema}
				validateOnBlur={false}
			>
				{({ isSubmitting }) => (
					<Form>
						<FormikTextField
							displayName={t("password")}
							field="password"
							type="password"
							autoFocus={true}
						/>
						<FormikTextField
							displayName={t("confirmPassword")}
							field="confirmPassword"
							type="password"
						/>
						<DisplayErrors errors={errors} />
						<div
							className="buttons"
							style={{
								display: "flex",
								gap: "16px",
								padding: "16px 8px 8px 0",
								justifyContent: "flex-end",
							}}
						>
							<Button variant="contained" type="submit" disabled={isSubmitting}>
								{t("resetPassword")}
							</Button>
							<Button variant="outlined" onClick={handleClose}>
								{t("cancel")}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</ModalContent>
	);
};

export default ResetPasswordContent;
