import React, {
	createContext,
	useEffect,
	useState,
	ReactNode,
	Dispatch,
	SetStateAction,
} from "react";
import * as signalR from "@microsoft/signalr";
import { urlChatSignalR } from "../config/endpoints";
import { getToken } from "../core/handleJWT";
import { messageDTO } from "../core/dto/dto.models";

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
	children,
}) => {
	const [connection, setConnection] = useState<signalR.HubConnection | null>(
		null
	);
	const [messageReceivedSignalR, setMessageReceivedSignalR] =
		useState<messageDTO | null>(null);
	const [messageReadSignalR, setMessageReadSignalR] = useState<string | null>(
		null
	);
	const [removeLastMessageSignalR, setRemoveLastMessageSignalR] = useState<
		string | null
	>(null);
	const [token, setToken] = useState<string | null>(getToken());

	useEffect(() => {
		const connect = async (newToken: string) => {
			if (connection) {
				connection.stop();
			}

			try {
				const newConnection = new signalR.HubConnectionBuilder()
					.withUrl(urlChatSignalR, { accessTokenFactory: () => newToken })
					.configureLogging(signalR.LogLevel.Information)
					.withAutomaticReconnect()
					.build();

				newConnection.on("ReceiveMessage", (message: messageDTO) =>
					setMessageReceivedSignalR(message)
				);
				newConnection.on("MarkChatAsRead", (chatId: string) =>
					setMessageReadSignalR(chatId)
				);
				newConnection.on("RemoveLastMessage", (chatId: string) =>
					setRemoveLastMessageSignalR(chatId)
				);

				await newConnection
					.start()
					.catch((error) =>
						console.error("Error while starting connection", error)
					);
				setConnection(newConnection);
			} catch (error) {
				// console.error(error);
			}
		};

		if (token !== null && token !== undefined && token.length > 0) {
			connect(token);
		}

		return () => {
			if (connection) {
				connection.stop();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	useEffect(() => {
		const interval = setInterval(() => {
			const currentToken = getToken();
			if (currentToken !== null && currentToken !== token) {
				setToken(currentToken);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [token]);

	return (
		<WebSocketContext.Provider
			value={{
				connection,
				messageReceivedSignalR,
				messageReadSignalR,
				removeLastMessageSignalR,
				setMessageReceivedSignalR,
				setMessageReadSignalR,
				setRemoveLastMessageSignalR,
			}}
		>
			{children}
		</WebSocketContext.Provider>
	);
};

interface WebSocketProviderProps {
	children: ReactNode;
}

interface WebSocketContextType {
	connection: signalR.HubConnection | null;
	messageReceivedSignalR: messageDTO | null;
	messageReadSignalR: string | null;
	removeLastMessageSignalR: string | null;
	setMessageReceivedSignalR: Dispatch<SetStateAction<messageDTO | null>>;
	setMessageReadSignalR: Dispatch<SetStateAction<string | null>>;
	setRemoveLastMessageSignalR: Dispatch<SetStateAction<string | null>>;
}

export default WebSocketContext;
