// Order of the roles is important because
// they need to match the backend

export enum AcademicDegree {
	None = 0,
	Vocational = 1,
	Professional = 2,
	Bachelor = 3,
	Master = 4,
	PhD = 5,
}

export enum Availability {
	None = 0,
	Immediately = 1,
	AfterOneMonth = 2,
	AfterThreeMonths = 3,
	AfterSixMonths = 4,
	InConsultation = 5,
}

export enum EducationLevel {
	None = 0,
	Bachelor = 1,
	CareerChanger = 2,
	Certified = 3,
	Diploma = 4,
	Doctor = 5,
	FH = 6,
	FurtherEducationAndCertificates = 7,
	HF = 8,
	Master = 9,
	ProfessionalTrainings = 10,
	Specialized = 11,
	Other = 12,
}

export enum EmploymentType {
	None = 0,
	Temporary = 1,
	Hourly = 2,
	Permanent = 3,
	Internship = 4,
	Freelance = 5,
	Apprenciceship = 6,
	FullTime = 7,
	PartTime = 8,
	ShortTerm = 9,
	VolunteerWork = 10,
}

export enum FileType {
	// Users
	UserProfilePicture = 0,
	Certificate = 1,
	AboutMeVideo = 2,
	ProfilePictureBlurred = 3,
	UserHeader = 4,

	// Companies
	CompanyProfilePicture = 50,
	CompanyHeader = 51,
}

export enum FieldOfStudy {
	None = 0,
	MathematicalSciences = 1,
	Computing = 2,
	PhysicalSciences = 3,
	LifeSciences = 4,
	EngineeringSciences = 5,
	HealthSciences = 6,
	SocialSciences = 7,
	Law = 8,
	Business = 9,
	Arts = 10,
	Humanities = 11,
	Education = 12,
	Sports = 13,
	Other = 14,
}

export enum LanguageNiveau {
	None = 0,
	A1 = 1,
	A2 = 2,
	B1 = 3,
	B2 = 4,
	C1 = 5,
	C2 = 6,
	Native = 7,
}

export enum Workload {
	None = 0,
	ZeroToTwentyPercent = 1,
	TwentyToFortyPercent = 2,
	FortyToSixtyPercent = 3,
	SixtyToEightyPercent = 4,
	EightyToOneHundredPercent = 5,
}

export enum JobPosition {
	None = 0,
	Employed = 1,
	EmployedWithResponsibility = 2,
	TeamLeader = 3,
	Assistance = 4,
	HeadOfDepartment = 5,
	Specialist = 6,
	HeadOf = 7,
	CEO = 8,
	CFO = 9,
	COO = 10,
}

export enum Salary {
	None = 0,
	ZeroToTwentyK = 1,
	TwentyToFortyK = 2,
	FortyToSixtyK = 3,
	SixtyToEightyK = 4,
	EightyToOneHundredK = 5,
	OneHundredToOpenK = 6,
}

export enum Title {
	None = 0,
	Mr = 1,
	Ms = 2,
	Dr = 3,
	Prof = 4,
}

export enum UserRoles {
	Admin = 0,
	Recrewter = 1,
	Employee = 2,
}

export enum YearsOfExperience {
	None = 0,
	OneToThree = 1,
	ThreeToFive = 2,
	FiveToTen = 3,
	TenToTwenty = 4,
	TwentyPlus = 5,
}

export enum ConnectionStatus {
	None = 0,
	Requested = 1,
	Accepted = 2,
	Declined = 3,
}

export enum FeedbackMode {
	None = 0,
	Success = 1,
	Error = 2,
	Info = 3,
}

export enum ImageType {
	ProfileImage = "profile-picture",
	HeaderImage = "header-image",
	CompanyLogo = "company-logo-image",
	CompanyHeader = "company-header-image",
}
