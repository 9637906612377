import { FormControl, IconButton, OutlinedInput } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface textFieldProps {
	autoFocus?: boolean;
	field: string;
	placeholder?: string;
	displayName?: string;
	icon?: string;
	iconAlt?: string;
	type?: "text" | "password";
	class?: string;
	isRequired?: string;
	as?: string;
}

export default function FormikTextField({
	type = "text",
	class: className = "mb-3",
	autoFocus = false,
	isRequired = "true",
	...props
}: textFieldProps) {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);

	const inputType = type === "password" && !showPassword ? "password" : "text";

	return (
		<div className={`${className}`} style={{ margin: "10px" }}>
			<div
				className={`input-wrapper form-floating`}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<FormControl>
					<label htmlFor={props.field} className="floating-label">
						{props.displayName ? t(props.displayName) : ""}
					</label>
					<Field
						autoFocus={autoFocus}
						as={OutlinedInput}
						isrequired={isRequired}
						type={inputType}
						className={`form-control ${type === "password" ? "with-icon" : ""}`}
						id={props.field}
						name={props.field}
						placeholder={props.placeholder}
						autoComplete={type === "password" ? "new-password" : ""}
						endAdornment={
							type === "password" && (
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
									edge="end"
								>
									{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
								</IconButton>
							)
						}
					/>
					<ErrorMessage name={props.field}>
						{(msg) => (
							<div className="text-danger" style={{ color: "red" }}>
								{t(msg)}
							</div>
						)}
					</ErrorMessage>
				</FormControl>
			</div>
		</div>
	);
}
