import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface DropdownFieldProps {
	displayName?: string;
	field: string;
	selected?: number | string | undefined;
	onChange: (e: SelectChangeEvent<string>) => void;
	validationMessage?: string;
	className?: string;
	index?: number;
	indexSize?: number;
	disabled?: boolean;
	enumType: any;
	isMandatory?: boolean;
	autoFocus?: boolean;
}

const DropdownField: React.FC<DropdownFieldProps> = ({
	className = "mb-3",
	disabled = false,
	indexSize = 100,
	isMandatory = false,
	...props
}) => {
	const { t } = useTranslation();

	const [enumOptions, setEnumOptions] = useState<string[]>([]);

	useEffect(() => {
		setEnumOptions(() => {
			const filteredKeys = Object.keys(props.enumType)
				.filter((k) => isNaN(Number(k)))
				.filter((k) => {
					if (props.index === undefined) {
						return true;
					} else {
						const enumKey = k as keyof typeof props.enumType;
						return (
							props.enumType[enumKey] === props.index ||
							(props.enumType[enumKey] >= props.index + 1 &&
								props.enumType[enumKey] < props.index + indexSize!)
						);
					}
				});
			if (props.index !== undefined) {
				return ["None", ...filteredKeys];
			} else {
				return filteredKeys;
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.enumType, props.index, indexSize]);

	const onChange = (e: SelectChangeEvent<string>) => {
		const enumValue =
			props.enumType[e.target.value as keyof typeof props.enumType];
		const newE = {
			...e,
			target: { ...e.target, value: enumValue, name: e.target.name },
		};
		props.onChange(newE as SelectChangeEvent<string>);
	};

	const selectedKey =
		Object.keys(props.enumType).find(
			(key) =>
				props.enumType[key as keyof typeof props.enumType] === props.selected
		) || " ";

	return (
		<>
			<FormControl
				fullWidth
				variant="outlined"
				className={disabled ? "disabled" : ""}
				error={!!props.validationMessage}
			>
				{props.displayName && (
					<label htmlFor={props.field}>{`${t(props.displayName)}`}</label>
				)}
				<Select
					value={selectedKey}
					id={props.field}
					name={props.field}
					disabled={disabled}
					onChange={onChange}
					autoFocus={props.autoFocus}
				>
					<MenuItem value=" " disabled>
						{t("placeholderSelect")}{" "}
						{props.displayName ? t(props.displayName) : ""}
					</MenuItem>
					{enumOptions.map((key) => (
						<MenuItem value={key} key={key}>
							{t(key)}
						</MenuItem>
					))}
				</Select>
				{props.validationMessage && (
					<label className="text-danger" style={{ color: "red" }}>
						{t(props.validationMessage)}
					</label>
				)}
			</FormControl>
		</>
	);
};

export default DropdownField;
