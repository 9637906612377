/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import images from "../../../assets/images";
import {
	Availability,
	EducationLevel,
	JobPosition,
	Salary,
	Workload,
} from "../../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../../config/Types/MedicalEnumDefinitions";
import {
	getBackgroundPhotoAPI,
	getProfilePhotoAPI,
} from "../../../core/apiFunctions";
import { getNameIdentifier } from "../../../core/claimFunctions";
import { employeeSearchDTO } from "../../../core/dto/dto.models";
import { enumToPercentage } from "../../../core/helperConst";
import ProgressWithToolTip from "../../../utils/ProgressWithToolTip";
import AuthenticationContext from "../../auth/AuthenticationContext";

const defaultImage = images.DefaultProfilePhoto;
type Props = {
	employee: employeeSearchDTO;
	handleStarClick: (employee: employeeSearchDTO, userId: string) => void;
	isFavorite: Record<string, string[]>;
};

export const SearchResultCard = ({
	employee,
	isFavorite,
	handleStarClick,
}: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { claims } = useContext(AuthenticationContext);
	const currentRecrewter = getNameIdentifier(claims);
	const cardClickEvent = (userId: string) => {
		navigate("/staff/cv/" + userId);
	};

	const [Photos, setPhotos] = useState<{
		profile: string;
		background: string | null;
	}>({
		profile: defaultImage,
		background: "",
	});

	useEffect(() => {
		try {
			getProfilePhotoAPI(employee.userId).then((blobProfile) => {
				const profile = blobProfile
					? URL.createObjectURL(blobProfile.data)
					: defaultImage;

				getBackgroundPhotoAPI(employee.userId).then((blobBackground) => {
					const Background = blobBackground
						? URL.createObjectURL(blobBackground.data)
						: null; // Use default styling if null
					setPhotos({
						profile: profile,
						background: Background,
					});
				});
			});
		} catch (error) {
			setPhotos((prev) => ({
				...prev,
				profile: defaultImage,
			}));
		}

		try {
		} catch (error) {
			// When there is no customized headerPicture (background), styling in "SearchResults.scss > .resultsCardHeader (background-image)" is used
		}
	}, [employee]);

	return (
		<div className="resultsCard">
			<div
				className="resultsCardHeader"
				style={{
					backgroundImage: Photos.background
						? Photos.background && `url(${Photos.background})`
						: "",
				}}
			>
				<a onClick={() => cardClickEvent(employee.userId)}>
					<div className="resultsProfileImage">
						<img alt={"profilepicture"} src={Photos.profile} />
					</div>
				</a>
				<div>
					<div>
						<IconButton
							onClick={() => handleStarClick(employee, employee.userId)}
						>
							<img
								src={
									isFavorite[currentRecrewter]?.includes(employee.userId)
										? images.StarActive
										: images.Star
								}
								alt="favorite"
							/>
						</IconButton>
					</div>
					<div>
						<a onClick={() => navigate("/chat/" + employee.userId)}>
							<IconButton>
								<img src={images.ChatWhite} alt="chat" />
							</IconButton>
						</a>
					</div>
				</div>
			</div>

			<a onClick={() => cardClickEvent(employee.userId)}>
				<div className="resultsAbout">
					<h6 className="resultsTitle">{t("aboutMe")}</h6>
					<p>{employee.aboutMe}</p>
				</div>

				<div>
					<h6 className="resultsTitle">{t("myDreamJob")}</h6>
					<div className="resultsDreamJob">
						<div>
							<p>{t("headerEducationLevel")}:</p>
							<p>{t(EducationLevel[employee.dreamJob.educationLevel])}</p>
						</div>
						<div>
							<p>{t("division")}:</p>
							<p>{t(MedicalDivision[employee.dreamJob.division])}</p>
						</div>
						<div>
							<p>{t("position")}:</p>
							<p>{t(JobPosition[employee.dreamJob.jobPosition])}</p>
						</div>
					</div>
					<ProgressWithToolTip
						value={enumToPercentage(employee.dreamJob.workload, Workload)}
						title={t("pensum")}
						toolTip={
							enumToPercentage(employee.dreamJob.workload, Workload) + "%"
						}
					/>
					<ProgressWithToolTip
						value={enumToPercentage(employee.dreamJob.salary, Salary)}
						title={t("headerSalary")}
						toolTip={t(Salary[employee.dreamJob.salary])}
					/>
					<div className="resultsDreamJob">
						<div>
							<p>{t("startDate")}:</p>
							<p>{t(Availability[employee.dreamJob.availability])}</p>
						</div>
						<div className="buttonProfile">
							<Button type="button" variant="contained">
								{t("viewFullProfile")}
							</Button>
						</div>
					</div>
				</div>
			</a>
		</div>
	);
};
