import Grid from "@mui/material/Grid2";
import images from "../../assets/images";

import { Button, IconButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import DropdownField from "../../components/common/DropdownField";
import {
	AcademicDegree,
	Availability,
	EducationLevel,
	EmploymentType,
	FieldOfStudy,
	FileType,
	JobPosition,
	LanguageNiveau,
	Salary,
	UserRoles,
	Workload,
	YearsOfExperience,
} from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import {
	Country,
	NationalityType,
	State,
} from "../../config/Types/PlaceEnumDefinitions";
import {
	getCertificatesAPI,
	submitAboutMeAPI,
	submitCertificatesAPI,
	submitDreamJobAPI,
	submitEducationsAPI,
	submitIdealCandidateAPI,
	submitLanguageSkillsAPI,
	submitSkillsAPI,
	submitUserDetailsAPI,
	submitWorkExperienceAPI,
} from "../../core/apiFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import {
	aboutMeDTO,
	certificateDTO,
	educationDTO,
	idealCandidateDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	workExperienceDTO,
} from "../../core/dto/user.models";
import { getClaims, saveToken } from "../../core/handleJWT";
import {
	handleOnChange,
	listOfCountriesWithStates,
} from "../../core/helperConst";
import FileUploadField from "../../utils/FileUploadField";
import TextField from "../../utils/TextField";
import { useSetState } from "../../utils/UseSetState";
import useFeedback from "../../utils/useFeedback";
import AuthenticationContext from "../auth/AuthenticationContext";
import DisplayErrors from "../common/DisplayErrors";
import { getRoleId } from "../../core/claimFunctions";
import { useLoading } from "../../contexts/LoadingContext";

type Props = {
	userDetails: userDetailsDTO | undefined;
	dreamJob: internalJobDTO;
	handleAlignment: (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string | null
	) => void;
	alignment: string | null;
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
	isEditing: boolean;
	setDreamJob: React.Dispatch<React.SetStateAction<internalJobDTO>>;
	setBasicDetails: React.Dispatch<
		React.SetStateAction<userDetailsDTO | undefined>
	>;
	onUserProfileCompleted?: () => void;
	setUserDetailsInitial: React.Dispatch<
		React.SetStateAction<userDetailsDTO | undefined>
	>;
	aboutMeData: aboutMeDTO | undefined;
	setAboutMeData: React.Dispatch<React.SetStateAction<aboutMeDTO | undefined>>;
	setAboutMeInitialData: React.Dispatch<
		React.SetStateAction<aboutMeDTO | undefined>
	>;
	cvVideo: string | undefined;
	setCvVideo: React.Dispatch<React.SetStateAction<string | undefined>>;
	setDreamJobInitial: React.Dispatch<React.SetStateAction<internalJobDTO>>;
	languageSkills: languageSkillDTO[];
	skills: skillDTO[];
	setSkills: React.Dispatch<React.SetStateAction<skillDTO[]>>;
	setSkillsInitial: React.Dispatch<React.SetStateAction<skillDTO[]>>;
	setLanguageSkills: React.Dispatch<React.SetStateAction<languageSkillDTO[]>>;
	setLanguageSkillsInitial: React.Dispatch<
		React.SetStateAction<languageSkillDTO[]>
	>;
	workExperiences: workExperienceDTO[];
	setWorkExperiences: React.Dispatch<React.SetStateAction<workExperienceDTO[]>>;
	setWorkExperiencesInitial: React.Dispatch<
		React.SetStateAction<workExperienceDTO[]>
	>;
	setEducationsInitial: React.Dispatch<React.SetStateAction<educationDTO[]>>;
	educations: educationDTO[];
	setEducations: React.Dispatch<React.SetStateAction<educationDTO[]>>;
	certificates: certificateDTO[];
	setCertificates: React.Dispatch<React.SetStateAction<certificateDTO[]>>;
	setCertificatesInitial: React.Dispatch<
		React.SetStateAction<certificateDTO[]>
	>;
	idealCandidate: idealCandidateDTO;
	setInitialIdealCandidate: React.Dispatch<
		React.SetStateAction<idealCandidateDTO>
	>;
	setIdealCandidate: React.Dispatch<React.SetStateAction<idealCandidateDTO>>;
	handleSuccess: () => void;
	handleCancel: () => void;
	handleScroll: (id: string) => void;
	profileCompleted: boolean;
};
export const EditMyProfile = ({
	dreamJob,
	userDetails,
	setIsEditing,
	isEditing,
	setDreamJob,
	setBasicDetails,
	onUserProfileCompleted,
	setUserDetailsInitial,
	aboutMeData,
	setAboutMeData,
	setAboutMeInitialData,
	cvVideo,
	setCvVideo,
	setDreamJobInitial,
	skills,
	languageSkills,
	setLanguageSkills,
	setSkills,
	setSkillsInitial,
	setLanguageSkillsInitial,
	handleSuccess,
	handleCancel,
	workExperiences,
	setWorkExperiences,
	setWorkExperiencesInitial,
	setEducationsInitial,
	educations,
	setEducations,
	setCertificates,
	setCertificatesInitial,
	certificates,
	idealCandidate,
	setInitialIdealCandidate,
	setIdealCandidate,
	handleScroll,
	profileCompleted,
}: Props) => {
	const { update } = useContext(AuthenticationContext);
	const { claims } = useContext(AuthenticationContext);
	const { setLoading } = useLoading();

	const [role] = useState<UserRoles>(getRoleId(claims) ?? 0);
	const { t } = useTranslation();
	const { showSuccess, showError } = useFeedback();
	const dreamJobOnChange = handleOnChange(dreamJob, setDreamJob, true);
	const idealCandidateOnChange = handleOnChange(
		idealCandidate,
		setIdealCandidate,
		true
	);
	const basicDetailOnChange = handleOnChange(
		userDetails,
		setBasicDetails,
		true
	);

	// const isNationality = (type: NationalityType, value?: string): boolean => {
	// 	return value
	// 		? (Nationalities[type]?.includes(value.toLowerCase()) ?? false)
	// 		: false;
	// };
	const [dreamErrors, setDreamErrorsErrors] = useState<string[]>([]);

	const currentDate = new Date();

	const userDetailFieldErrorEmptyValues = {
		title: "",
		firstName: "",
		lastName: "",
		dateOfBirth: "",
		nationality: "",
		state: "",
		residencePermit: "",
		mobileNumber: "",
		email: "",
	};
	const userDetailSchema = yup.object({
		title: yup.number().min(0, "TitleIsRequired"),
		firstName: yup
			.string()
			.matches(/^[a-zA-ZäöüÄÖÜßéèêàçâôûîëïÿÉÈÊÀÇÂÔÛÎËÏŸ\s]+$/, "noNumbers")
			.required("FirstNameRequired"),
		lastName: yup
			.string()
			.matches(/^[a-zA-ZäöüÄÖÜßéèêàçâôûîëïÿÉÈÊÀÇÂÔÛÎËÏŸ\s]+$/, "noNumbers")
			.required("LastNameRequired"),
		dateOfBirth: yup.string().required("BirthdayRequired"),
		nationality: yup.string().required("NationalityRequired"),
		state: yup.string().when("nationality", {
			is: (value: string | Country) =>
				!(
					NationalityType.SWISS === value ||
					NationalityType.AUSTRIAN === value ||
					NationalityType.GERMAN === value ||
					listOfCountriesWithStates.includes(Number(value) as Country)
				),
			then: yup.string().notRequired(),
			otherwise: yup
				.string()
				.required("StateRequired")
				.notOneOf([State.None.toString()], "StateRequired"),
		}),
		residencePermit: yup.string().when("nationality", {
			is: (value: string | Country) =>
				NationalityType.SWISS === value ||
				Country.Switzerland.toString() === value,
			then: yup.string().notRequired(),
			otherwise: yup.string().required("ResidencePermitRequired"),
		}),
		mobileNumber: yup.string().required("MobileNumberRequired"),
		email: yup.string().email("EmailNotValid").required("EmailRequired"),
	});

	const [
		userDetailFieldErrors,
		setUserDetailFieldErrors,
		getUserDetailFieldErrors,
	] = useSetState(userDetailFieldErrorEmptyValues);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setIdealCandidate((prev) => ({
			...prev,
			state: listOfCountriesWithStates.includes(idealCandidate?.country)
				? idealCandidate.state
				: 0,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idealCandidate?.country]);

	useEffect(() => {
		setDreamJob((prev) => ({
			...prev,
			state: listOfCountriesWithStates.includes(dreamJob?.country)
				? dreamJob.state
				: 0,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dreamJob?.country]);

	const userDetailValidateNestedSchema = async () => {
		try {
			await userDetailSchema.validate(userDetails, { abortEarly: false });

			// If validation is successful, reset the field errors
			setUserDetailFieldErrors(userDetailFieldErrorEmptyValues);
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				const validationErrors: any = {};
				error.inner.forEach((innerError: any) => {
					validationErrors[innerError.path] = innerError.message;
				});
				setUserDetailFieldErrors(validationErrors);
			}
		}
	};

	const submitUserDetails = () => {
		return new Promise(async (resolve, reject) => {
			await userDetailValidateNestedSchema();

			const fieldErrors = await getUserDetailFieldErrors();
			if (Object.values(fieldErrors).every((x) => x === "")) {
				resolve(true);
			} else {
				reject(
					Object.entries(fieldErrors).find(([key, value]) =>
						Boolean(value)
					)?.[0] ?? ""
				);
			}
		});
	};

	//about me logic
	const [uplaodTigger, setUploadTigger] = useState<boolean>(false);
	const [videoHasChanged, setVideoHasChanged] = useState<boolean>(false);

	const aboutMeHandleOnChange = (e: any) => {
		const { name, value } = e.target;
		setAboutMeData({ ...aboutMeData!, [name]: value });
	};
	const handleOnChangeForFile = (e: any) => {
		e.targets.forEach((target: { name: any; value: any }) => {
			const { name, value } = target;
			setAboutMeData({ ...aboutMeData!, [name]: value });
		});
	};

	// is called when the file upload component has a new source
	const handleOnNewSource = (source: string, fileName: string) => {
		setCvVideo(source);
		setAboutMeData({ ...aboutMeData!, filePath: source, videoName: fileName });
		setVideoHasChanged(true);
		setIsEditing(true);
	};

	// is called when the file upload component has finished uploading the file to the server
	const handleUploadDone = async (filePath: string, fileName: string) => {
		const newAboutMe = {
			...aboutMeData!,
			filePath: filePath,
			videoName: fileName,
		};
		submitAboutMeAPI(newAboutMe);
		setAboutMeData(newAboutMe);
		setAboutMeInitialData(newAboutMe);
		setUploadTigger(false);
	};

	// remove the current video
	const handleOnRemove = () => {
		setCvVideo(undefined);
		setAboutMeData({ ...aboutMeData!, filePath: "", videoName: "" });
	};

	const aboutMeDataSubmit = async () => {
		// upload video if video is local
		if (videoHasChanged && cvVideo && cvVideo.startsWith("blob")) {
			// Check if file size is greater 250MB
			const video = await fetch(cvVideo);

			const videoBlob = await video.blob();
			if (videoBlob.size > 250000000) {
				showError("error.fileTooLarge250");
				return;
			}

			setUploadTigger(true);
		} else {
			submitAboutMeAPI(aboutMeData!);
			setAboutMeInitialData(aboutMeData);
		}
	};

	//language skills logic
	const [
		languageSkillsFieldErrors,
		setLanguageSkillsFieldErrors,
		getLanguageSkillsFieldErrors,
	] = useSetState<any[]>([]);

	const languageSkillsSchema = yup.object({
		language: yup.string().required("languageRequired"),
		niveau: yup.number().min(1, "niveauRequired"),
	});

	const initFieldErrors = () => {
		setLanguageSkillsFieldErrors(
			languageSkills.map(() => {
				return {
					language: "",
					niveau: "",
				};
			})
		);
	};
	const setSingleFieldError = (i: number, name: string, value: string) => {
		const allFieldErrors = [...languageSkillsFieldErrors];
		const modifiedFieldError = {
			...languageSkillsFieldErrors[i],
			[name]: value,
		};
		allFieldErrors[i] = modifiedFieldError;
		setLanguageSkillsFieldErrors(allFieldErrors);
	};
	const languageSkillHandleOnChange = (i: number, e: any) => {
		if (!isEditing) {
			setIsEditing(true);
		}

		// check if fielderrors are initialized
		if (languageSkillsFieldErrors.length === 0) {
			initFieldErrors();
		}

		const { name, value } = e.target;
		const allSkills = [...languageSkills];
		allSkills[i] = { ...allSkills[i], [name]: value };
		setLanguageSkills(allSkills);

		// validate single field at index i
		languageSkillsSchema
			.validateAt(name, allSkills[i])
			.then(() => {
				setSingleFieldError(i, name, "");
			})
			.catch((err) => {
				const errors = err.errors.map((err: any) => err);
				setSingleFieldError(i, name, errors[0]);
			});
	};

	const languageValidateNestedSchema = async () => {
		// foreach each languageSkill, validate the fields
		const errors = await Promise.all(
			languageSkills.map(async (languageSkill) => {
				const errors = await languageSkillsSchema
					.validate(languageSkill, { abortEarly: false })
					.catch((err) => {
						return err;
					});

				if (errors instanceof yup.ValidationError) {
					const validationErrors: any = {};
					errors.inner.forEach((error: any) => {
						validationErrors[error.path] = error.message;
					});
					return validationErrors;
				}
				return {};
			})
		);

		setLanguageSkillsFieldErrors(errors);
	};

	const languageSubmit = () => {
		return new Promise(async (resolve, reject) => {
			await languageValidateNestedSchema();
			// getFieldErrors is needed, because react needs internal state to be updated
			const fieldErrors = await getLanguageSkillsFieldErrors();
			const hasErrors = fieldErrors.some((fieldError) => {
				return Object.keys(fieldError).length > 0;
			});

			if (!hasErrors) {
				resolve(true);
			} else {
				reject(
					Object.entries(
						fieldErrors.filter((x) => Object.keys(x).length !== 0)[0]
					).find(([key, value]) => Boolean(value))?.[0] ?? ""
				);
			}
		});
	};
	const languageHandleOnRemove = (i: number) => {
		const allSkills = [...languageSkills];
		allSkills.splice(i, 1);
		setLanguageSkills(allSkills);
		const allFieldErrors = [...languageSkillsFieldErrors];
		allFieldErrors.splice(i, 1);
		setLanguageSkillsFieldErrors(allFieldErrors);
	};

	const setLanguageSkillsHandleOnAdd = () => {
		const newSkill = {
			id: "",
			language: "",
			niveau: LanguageNiveau.None,
		};
		setLanguageSkills([...languageSkills, newSkill]);
		setLanguageSkillsFieldErrors([
			...languageSkillsFieldErrors,
			{
				language: "",
				niveau: "",
			},
		]);
	};

	//skills  logic
	const [skillsFieldErrors, setSkillsFieldErrors, getSkillsFieldErrors] =
		useSetState<any[]>([]);

	const skillHandleOnRemove = (i: number) => {
		const allSkills = [...skills];
		allSkills.splice(i, 1);
		setSkills(allSkills);
		const allFieldErrors = [...skillsFieldErrors];
		allFieldErrors.splice(i, 1);
		setSkillsFieldErrors(allFieldErrors);
	};

	const skillHandleOnAdd = () => {
		const newSkill = {
			id: "",
			name: "",
			years: YearsOfExperience.None,
		};
		setSkills([...skills, newSkill]);
		setSkillsFieldErrors([
			...skillsFieldErrors,
			{
				name: "",
				years: "",
			},
		]);
	};

	const SkillSchema = yup.object({
		name: yup.string().required("nameRequired"),
		years: yup.number().min(1, "yearsRequired"),
	});
	const skillHandleOnChange = (i: number, e: any) => {
		if (!isEditing) {
			setIsEditing(true);
		}

		// check if fielderrors are initialized
		if (skillsFieldErrors.length === 0) {
			initFieldErrors();
		}

		const { name, value } = e.target;
		const allSkills = [...skills];
		allSkills[i] = { ...allSkills[i], [name]: value };
		setSkills(allSkills);

		// validate single field at index i
		SkillSchema.validateAt(name, allSkills[i])
			.then(() => {
				setSingleFieldError(i, name, "");
			})
			.catch((err) => {
				const errors = err.errors.map((err: any) => err);
				setSingleFieldError(i, name, errors[0]);
			});
	};

	const skillValidateNestedSchema = async () => {
		// foreach each skill, validate the fields
		const errors = await Promise.all(
			skills.map(async (skill) => {
				const errors = await SkillSchema.validate(skill, {
					abortEarly: false,
				}).catch((err) => {
					return err;
				});

				if (errors instanceof yup.ValidationError) {
					const validationErrors: any = {};
					errors.inner.forEach((error: any) => {
						validationErrors[error.path] = error.message;
					});
					return validationErrors;
				}
				return {};
			})
		);

		setSkillsFieldErrors(errors);
	};

	const skillsSubmit = () => {
		return new Promise(async (resolve, reject) => {
			await skillValidateNestedSchema();
			// getFieldErrors is needed, because react needs internal state to be updated

			const fieldErrors = await getSkillsFieldErrors();
			const hasErrors = fieldErrors.some((fieldError) => {
				return Object.keys(fieldError).length > 0;
			});

			if (!hasErrors) {
				resolve(true);
			} else {
				reject(
					Object.entries(
						fieldErrors.filter((x) => Object.keys(x).length !== 0)[0]
					).find(([key, value]) => Boolean(value))?.[0] ?? ""
				);
			}
		});
	};

	// workExperience logic

	const experienceSchema = yup.object({
		jobTitle: yup.string().required("jobTitleRequired"),
		company: yup.string().required("companyRequired"),
		address: yup.string().required("addressRequired"),
		startDate: yup.date().required("startDateRequired"),
		endDate: yup.date().required("endDateRequired"),
	});

	const [
		experienceFieldErrors,
		setExperienceFieldErrors,
		getExperienceFieldErrors,
	] = useSetState<workExperienceDTO[]>([]);

	const experienceHandleOnChange = (i: number, e: any) => {
		if (!isEditing) {
			setIsEditing(true);
		}

		// check if fielderrors are initialized
		if (experienceFieldErrors.length === 0) {
			initFieldErrors();
		}

		const { name, value } = e.target;
		const allExperiences = [...workExperiences];
		allExperiences[i] = { ...allExperiences[i], [name]: value };
		setWorkExperiences(allExperiences);

		// validate single field at index i
		experienceSchema
			.validateAt(name, allExperiences[i])
			.then(() => {
				setSingleFieldError(i, name, "");
			})
			.catch((err) => {
				const errors = err.errors.map((err: any) => err);
				setSingleFieldError(i, name, errors[0]);
			});
	};

	const experienceHandleOnAdd = () => {
		const newSkill = {
			id: "",
			jobTitle: "",
			company: "",
			address: "",
			startDate: new Date().toISOString().split("T")[0],
			endDate: new Date().toISOString().split("T")[0],
		};
		setWorkExperiences([...workExperiences, newSkill]);
		setExperienceFieldErrors([
			...experienceFieldErrors,
			{
				jobTitle: "",
				company: "",
				address: "",
				startDate: "",
				endDate: "",
			},
		]);

		if (!isEditing) {
			setIsEditing(true);
		}
	};

	const experienceHandleOnRemove = (i: number) => {
		const allSkills = [...workExperiences];
		allSkills.splice(i, 1);
		setWorkExperiences(allSkills);
		const allFieldErrors = [...experienceFieldErrors];
		allFieldErrors.splice(i, 1);
		setExperienceFieldErrors(allFieldErrors);
	};

	const experienceValidateNestedSchema = async () => {
		// foreach each languageSkill, validate the fields
		const errors = await Promise.all(
			workExperiences.map(async (workExperience) => {
				const errors = await experienceSchema
					.validate(workExperience, { abortEarly: false })
					.catch((err) => {
						return err;
					});

				if (errors instanceof yup.ValidationError) {
					const validationErrors: any = {};
					errors.inner.forEach((error: any) => {
						validationErrors[error.path] = error.message;
					});
					return validationErrors;
				}
				return {};
			})
		);

		setExperienceFieldErrors(errors);
	};
	const experienceSubmit = async () => {
		return new Promise(async (resolve, reject) => {
			await experienceValidateNestedSchema();
			// getFieldErrors is needed, because react needs internal state to be updated
			const fieldErrors = await getExperienceFieldErrors();
			const hasErrors = fieldErrors.some((fieldError) => {
				return Object.keys(fieldError).length > 0;
			});

			if (!hasErrors) {
				resolve(true);
				return;
			}

			reject(
				Object.entries(
					fieldErrors.filter((x) => Object.keys(x).length !== 0)[0]
				).find(([key, value]) => Boolean(value))?.[0] ?? ""
			);
		});
	};
	//

	//education logic
	const [
		educationFieldErrors,
		setEducationFieldErrors,
		getEducationFieldErrors,
	] = useSetState<any[]>([]);

	const educationSchema = yup.object({
		fieldOfStudy: yup.number().min(0, "fieldOfStudyRequired"),
		academicDegree: yup.number().min(0, "academicDegreeRequired"),
		institution: yup.string().required("institutionRequired"),
		address: yup.string().required("addressRequired"),
		startDate: yup.date().required("startDateRequired"),
		endDate: yup.date().required("endDateRequired"),
	});

	const educationValidateNestedSchema = async () => {
		// foreach each languageSkill, validate the fields
		const errors = await Promise.all(
			educations.map(async (languageSkill) => {
				const errors = await educationSchema
					.validate(languageSkill, { abortEarly: false })
					.catch((err) => {
						return err;
					});

				if (errors instanceof yup.ValidationError) {
					const validationErrors: any = {};
					errors.inner.forEach((error: any) => {
						validationErrors[error.path] = error.message;
					});
					return validationErrors;
				}
				return {};
			})
		);

		setEducationFieldErrors(errors);
	};

	const educationSubmit = async () => {
		return new Promise(async (resolve, reject) => {
			await educationValidateNestedSchema();
			// getFieldErrors is needed, because react needs internal state to be updated
			const fieldErrors = await getEducationFieldErrors();
			const hasErrors = fieldErrors.some((fieldError) => {
				return Object.keys(fieldError).length > 0;
			});

			if (!hasErrors) {
				resolve(true);
				return;
			}
			reject(
				Object.entries(
					fieldErrors.filter((x) => Object.keys(x).length !== 0)[0]
				).find(([key, value]) => Boolean(value))?.[0] ?? ""
			);
		});
	};

	const educationHandleOnChange = (i: number, e: any) => {
		if (!isEditing) {
			setIsEditing(true);
		}

		// check if fielderrors are initialized
		if (educationFieldErrors.length === 0) {
			initFieldErrors();
		}

		const { name, value } = e.target;
		const allSkills = [...educations];
		allSkills[i] = { ...allSkills[i], [name]: value };
		setEducations(allSkills);

		// validate single field at index i
		educationSchema
			.validateAt(name, allSkills[i])
			.then(() => {
				setSingleFieldError(i, name, "");
			})
			.catch((err) => {
				const errors = err.errors.map((err: any) => err);
				setSingleFieldError(i, name, errors[0]);
			});
	};

	const educationHandleOnAdd = () => {
		const newSkill = {
			id: "",
			fieldOfStudy: FieldOfStudy.None,
			academicDegree: AcademicDegree.None,
			institution: "",
			address: "",
			startDate: new Date().toISOString().split("T")[0],
			endDate: new Date().toISOString().split("T")[0],
		};
		setEducations([...educations, newSkill]);
		setEducationFieldErrors([
			...educationFieldErrors,
			{
				fieldOfStudy: "",
				academicDegree: "",
				institution: "",
				address: "",
				startDate: "",
				endDate: "",
			},
		]);

		if (!isEditing) {
			setIsEditing(true);
		}
	};

	const educationHandleOnRemove = (i: number) => {
		const allSkills = [...educations];
		allSkills.splice(i, 1);
		setEducations(allSkills);
		const allFieldErrors = [...educationFieldErrors];
		allFieldErrors.splice(i, 1);
		setEducationFieldErrors(allFieldErrors);
	};

	///

	///certificate logic
	const [
		certificateFieldErrors,
		setCertificateFieldErrors,
		getCertificateFieldErrors,
	] = useSetState<any[]>([]);
	const certificateSchema = yup.object({
		title: yup.string().required("titleRequired"),
		description: yup.string().required("descriptionRequired"),
		institution: yup.string().required("institutionRequired"),
		fileName: yup.string().required("fileRequired"),
		filePath: yup.string().required("fileRequired"),
		startDate: yup.string().required("startDateRequired"),
		endDate: yup.string().required("endDateRequired"),
	});

	// reset the form to the original values

	const certificateValidateNestedSchema = async () => {
		// foreach each languageSkill, validate the fields
		const errors = await Promise.all(
			certificates.map(async (certificate) => {
				const errors = await certificateSchema
					.validate(certificate, { abortEarly: false })
					.catch((err) => {
						return err;
					});

				if (errors instanceof yup.ValidationError) {
					const validationErrors: any = {};
					errors.inner.forEach((error: any) => {
						validationErrors[error.path] = error.message;
					});
					return validationErrors;
				}
				return {};
			})
		);

		setCertificateFieldErrors(errors);
	};

	const certificateSubmit = async () => {
		return new Promise(async (resolve, reject) => {
			await certificateValidateNestedSchema();
			// getFieldErrors is needed, because react needs internal state to be updated
			const fieldErrors = await getCertificateFieldErrors();
			const hasErrors = fieldErrors.some((fieldError) => {
				return Object.keys(fieldError).length > 0;
			});
			if (!hasErrors) {
				resolve(true);
				return;
			}
			reject(
				Object.entries(fieldErrors).find(([key, value]) =>
					Boolean(value)
				)?.[0] ?? ""
			);
		});
	};
	const validteSingleFieldAtIndex = (
		i: number,
		name: string,
		certificate: certificateDTO
	) => {
		// validate single field at index i
		certificateSchema
			.validateAt(name, certificate)
			.then(() => {
				setSingleFieldError(i, name, "");
			})
			.catch((err) => {
				const errors = err.errors.map((err: any) => err);
				setSingleFieldError(i, name, errors[0]);
			});
	};

	const certificateHandleOnChangeForFile = (i: number, e: any) => {
		// initChange()
		const allCertificates = [...certificates];
		e.targets.forEach((target: { name: any; value: any }) => {
			const { name, value } = target;
			allCertificates[i] = { ...allCertificates[i], [name]: value };
			certificateValidateSingleFieldAtIndex(i, name, allCertificates[i]);
		});

		setCertificates(allCertificates);
	};

	const certificateSetSingleFieldError = (
		i: number,
		name: string,
		value: string
	) => {
		const allFieldErrors = [...certificateFieldErrors];
		const modifiedFieldError = { ...certificateFieldErrors[i], [name]: value };
		allFieldErrors[i] = modifiedFieldError;
		setCertificateFieldErrors(allFieldErrors);
	};

	const certificateValidateSingleFieldAtIndex = (
		i: number,
		name: string,
		certificate: certificateDTO
	) => {
		// validate single field at index i
		certificateSchema
			.validateAt(name, certificate)
			.then(() => {
				certificateSetSingleFieldError(i, name, "");
			})
			.catch((err) => {
				const errors = err.errors.map((err: any) => err);
				certificateSetSingleFieldError(i, name, errors[0]);
			});
	};

	const certificateHandleOnChange = (i: number, e: any) => {
		const { name, value } = e.target;
		const allCertificates = [...certificates];
		allCertificates[i] = { ...allCertificates[i], [name]: value };
		setCertificates(allCertificates);

		validteSingleFieldAtIndex(i, name, allCertificates[i]);
	};

	const certificateHandleOnAdd = () => {
		const certificate = {
			id: "",
			title: "",
			description: "",
			institution: "",
			fileName: "",
			filePath: "",
			startDate: new Date().toISOString().split("T")[0],
			endDate: new Date().toISOString().split("T")[0],
			isAnonymized: false,
		};

		setCertificates([...certificates, certificate]);
		setCertificateFieldErrors([
			...certificateFieldErrors,
			{
				title: "",
				description: "",
				institution: "",
				fileName: "",
				filePath: "",
				startDate: "",
				endDate: "",
			},
		]);
	};

	const certificateHandleOnRemove = (i: number) => {
		const allCertificates = [...certificates];
		allCertificates.splice(i, 1);
		setCertificates(allCertificates);
		const allFieldErrors = [...certificateFieldErrors];
		allFieldErrors.splice(i, 1);
		setCertificateFieldErrors(allFieldErrors);

		// check if current state is the same as the initial state
	};

	const userDetailPostApi = async () => {
		const response = await submitUserDetailsAPI(userDetails!);
		setUserDetailsInitial(userDetails);
		saveToken(response.data);
		update(getClaims());

		if (onUserProfileCompleted) {
			onUserProfileCompleted();
		}
	};

	const dreamJobPostApi = async () => {
		submitDreamJobAPI(dreamJob!)
			.then(() => {
				setDreamJobInitial(dreamJob);
				setDreamErrorsErrors([]);
				// showSuccess(t("successfullySaved"))
			})
			.catch((error) => {
				setDreamErrorsErrors(error.response.data);
			});
	};

	const idealCandidatePostAPi = () => {
		submitIdealCandidateAPI(idealCandidate)
			.then(() => {
				setInitialIdealCandidate(idealCandidate);
			})
			.catch((error: any) => console.error(error));
	};

	const skillsPostApi = async () => {
		await submitSkillsAPI(skills);
		setSkillsInitial(skills);
	};

	const languageSkillPostApi = async () => {
		await submitLanguageSkillsAPI(languageSkills);
		setLanguageSkillsInitial(languageSkills);
	};

	const experiencePostApi = async () => {
		await submitWorkExperienceAPI(workExperiences);
		setWorkExperiencesInitial(workExperiences);
	};

	const educationPostApi = async () => {
		await submitEducationsAPI(educations);
		setEducationsInitial(educations);
	};

	const certificatePostApi = async () => {
		await submitCertificatesAPI(certificates);

		await getCertificatesAPI().then((response) => {
			setCertificatesInitial(response.data);
			setCertificates(response.data);
		});
	};

	//final
	const finalSubmit = async () => {
		try {
			setLoading(true);
			await Promise.all(
				role === UserRoles.Employee
					? [
							submitUserDetails(),
							skillsSubmit(),
							languageSubmit(),
							aboutMeDataSubmit(),
							experienceSubmit(),
							educationSubmit(),
							certificateSubmit(),
						]
					: [submitUserDetails()]
			)
				.then(async (x) => {
					await Promise.all(
						role === UserRoles.Employee
							? [
									userDetailPostApi(),
									dreamJobPostApi(),
									skillsPostApi(),
									languageSkillPostApi(),
									experiencePostApi(),
									educationPostApi(),
									certificatePostApi(),
								]
							: [userDetailPostApi(), idealCandidatePostAPi()]
					)
						.then((x) => {
							showSuccess(t("successfullySaved"));
							setIsEditing(false);
							// handleSuccess();
						})
						.catch(() => {
							setIsEditing(false);
						});
				})
				.catch((errorsFiled) => {
					handleScroll(errorsFiled);
				})
				.finally(() => setLoading(false));
		} catch (error: any) {}
	};

	return (
		<>
			{/* { basic information start } */}
			<div className="basic-information card" id="basicInformation">
				<h6>{t("basicInformation")}</h6>
				<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
					{/* <Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<DropdownField
							displayName={"title"}
							field={"title"}
							selected={userDetails?.title}
							onChange={basicDetailOnChange}
							enumType={Title}
							validationMessage={userDetailFieldErrors.title}
						/>
					</Grid> */}
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"firstName"}
							field="firstName"
							type="text"
							value={userDetails?.firstName}
							onChange={basicDetailOnChange}
							placeholder={t("placeholderName")}
							validationMessage={userDetailFieldErrors.firstName}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"lastname"}
							field="lastName"
							type="text"
							value={userDetails?.lastName}
							onChange={basicDetailOnChange}
							placeholder={t("placeholderSurname")}
							validationMessage={userDetailFieldErrors.lastName}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<FormControl>
							<label>{t("birthday")}</label>
							<DatePicker
								format="DD-MM-YYYY"
								maxDate={dayjs(new Date())}
								minDate={dayjs(
									new Date().setFullYear(currentDate.getFullYear() - 150)
								)}
								value={
									userDetails?.dateOfBirth
										? dayjs(userDetails?.dateOfBirth)
										: null
								}
								onChange={(v) =>
									userDetails &&
									setBasicDetails({
										...userDetails,
										dateOfBirth: v ? v.format("YYYY-MM-DD") : "",
									})
								}
							/>
							{userDetailFieldErrors.dateOfBirth && (
								<div className="text-danger" style={{ color: "red" }}>
									{userDetailFieldErrors.dateOfBirth}
								</div>
							)}
						</FormControl>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<DropdownField
							displayName={"nationality"}
							field={"nationality"}
							selected={Number(userDetails?.nationality)}
							onChange={basicDetailOnChange}
							enumType={Country}
							validationMessage={userDetailFieldErrors.nationality}
						/>
						{/* <TextField
							displayName={"nationality"}
							field="nationality"
							type="text"
							value={userDetails?.nationality}
							onChange={basicDetailOnChange}
							placeholder={t("nationality")}
							validationMessage={userDetailFieldErrors.nationality}
						/> */}
					</Grid>
					{listOfCountriesWithStates.includes(
						Number(userDetails?.nationality)
					) && (
						<Grid size={{ sm: 6, md: 4, xs: 12 }}>
							<DropdownField
								displayName={
									userDetails?.nationality === Country.Switzerland.toString()
										? "canton"
										: "state"
								}
								field={"state"}
								selected={userDetails?.state}
								index={
									userDetails?.nationality === Country.Germany.toString()
										? 8400
										: userDetails?.nationality === Country.Austria.toString()
											? 1500
											: 21600 // Default for Switzerland
								}
								indexSize={
									userDetails?.nationality === Country.Germany.toString()
										? 17
										: userDetails?.nationality === Country.Austria.toString()
											? 10
											: 27 // Default for Switzerland (Canton)
								}
								disabled={
									userDetails?.nationality === Country.None.toString() ||
									!listOfCountriesWithStates.includes(
										Number(userDetails?.nationality)!
									)
								}
								onChange={basicDetailOnChange}
								enumType={State}
								validationMessage={userDetailFieldErrors.state}
							/>
						</Grid>
					)}
					{!(Country.Switzerland.toString() === userDetails?.nationality) && (
						<Grid size={{ sm: 6, md: 4, xs: 12 }}>
							<TextField
								displayName={"residencePermit"}
								field="residencePermit"
								type="text"
								value={userDetails?.residencePermit}
								onChange={basicDetailOnChange}
								placeholder={t("residencePermit")}
								validationMessage={userDetailFieldErrors.residencePermit}
							/>
						</Grid>
					)}

					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"phoneNumber"}
							field="phoneNumber"
							type="text"
							value={userDetails?.phoneNumber}
							onChange={basicDetailOnChange}
							placeholder={t("placeholderPhone")}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"mobileNumber"}
							field="mobileNumber"
							type="text"
							value={userDetails?.mobileNumber}
							onChange={basicDetailOnChange}
							placeholder={t("placeholderPhone")}
							validationMessage={userDetailFieldErrors.mobileNumber}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"eMail"}
							field="email"
							type="text"
							value={userDetails?.email}
							onChange={basicDetailOnChange}
							placeholder={t("placeholderEmail")}
							validationMessage={userDetailFieldErrors.email}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"website"}
							field="website"
							type="text"
							value={userDetails?.website}
							onChange={basicDetailOnChange}
							placeholder={t("website")}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"linkedIn"}
							field="linkedIn"
							type="text"
							value={userDetails?.linkedIn}
							onChange={basicDetailOnChange}
							placeholder={t("placeholderLinkedIn")}
						/>
					</Grid>
					<Grid size={{ sm: 6, md: 4, xs: 12 }}>
						<TextField
							displayName={"xing"}
							field="xing"
							type="text"
							value={userDetails?.xing}
							onChange={basicDetailOnChange}
							placeholder={t("xing")}
						/>
					</Grid>
				</Grid>
			</div>
			{/* { basic information end } */}

			{/* dream job start */}
			{role === UserRoles.Employee && (
				<>
					{/* job description start */}
					<div className="job-description card" id="myDreamJob">
						<h6>{t("myDreamJobDescription")}</h6>
						<TextField
							displayName={"description"}
							field="description"
							type="text"
							value={dreamJob?.description}
							onChange={dreamJobOnChange}
							isMultiline
							placeholder={t("dreamJobPlaceholder")}
							rows={5}
						/>
						<DisplayErrors errors={dreamErrors} />
					</div>
					{/* job description end */}
					<div className="dream-job card">
						<h6>{t("myDreamJob")}</h6>
						<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerCountry"}
									field={"country"}
									selected={dreamJob?.country}
									onChange={dreamJobOnChange}
									enumType={Country}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={
										dreamJob?.country === Country.Switzerland
											? "headerCanton"
											: "headerState"
									}
									field={"state"}
									index={
										dreamJob?.country === Country.Germany
											? 8400
											: dreamJob?.country === Country.Austria
												? 1500
												: 21600 // Default for Switzerland
									}
									indexSize={
										dreamJob?.country === Country.Germany
											? 17
											: dreamJob?.country === Country.Austria
												? 10
												: 27 // Default for Switzerland (Canton)
									}
									selected={
										!listOfCountriesWithStates.includes(dreamJob?.country)
											? State.None
											: dreamJob?.state!
									}
									disabled={
										dreamJob?.country === Country.None ||
										!listOfCountriesWithStates.includes(dreamJob?.country!)
									}
									onChange={dreamJobOnChange}
									enumType={State}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerEmployementType"}
									field={"employmentType"}
									selected={dreamJob?.employmentType}
									onChange={dreamJobOnChange}
									enumType={EmploymentType}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerAvailability"}
									field={"availability"}
									selected={dreamJob?.availability}
									onChange={dreamJobOnChange}
									enumType={Availability}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerPosition"}
									field={"jobPosition"}
									selected={dreamJob?.jobPosition}
									onChange={dreamJobOnChange}
									enumType={JobPosition}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerPensum"}
									field={"workload"}
									selected={dreamJob?.workload}
									onChange={dreamJobOnChange}
									enumType={Workload}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerSalary"}
									field={"salary"}
									selected={dreamJob?.salary}
									onChange={dreamJobOnChange}
									enumType={Salary}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"division"}
									field={"division"}
									selected={dreamJob?.division}
									onChange={dreamJobOnChange}
									enumType={MedicalDivision}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerEducationLevel"}
									field={"educationLevel"}
									selected={dreamJob?.educationLevel}
									onChange={dreamJobOnChange}
									enumType={EducationLevel}
								/>
							</Grid>
						</Grid>
					</div>
				</>
			)}

			{/* dream job end */}

			{/* my cv start */}
			{role === UserRoles.Employee && (
				<div className="card my-cv" id="myCv">
					<h6>{t("myCv")}</h6>
					<TextField
						displayName={"aboutMe"}
						field="aboutMe"
						type="text"
						className="textarea"
						value={aboutMeData?.aboutMe}
						onChange={aboutMeHandleOnChange}
						isMultiline
						placeholder={t("aboutMePlaceholder")}
						rows={5}
					/>

					<FileUploadField
						field={"fileUpload"}
						videoDescription="noVideo"
						isVideo
						videSource={cvVideo}
						fileName={aboutMeData?.videoName!}
						directUpload={false}
						filePath={aboutMeData?.filePath!}
						type={FileType.AboutMeVideo}
						accept="video/*"
						uploadTigger={uplaodTigger}
						onRemove={handleOnRemove}
						onNewSource={handleOnNewSource}
						onChange={handleOnChangeForFile}
						onUploadDone={handleUploadDone}
					/>

					<h6 className="">{t("languageSkills")}</h6>
					<Grid
						container
						spacing={{ xs: 3, sm: 4, lg: 5 }}
						className="items-group"
					>
						{languageSkills.map((lan, index) => (
							<Fragment key={`languageSkill-${index}`}>
								<Grid size={{ sm: 6, md: 6, xs: 12 }}>
									<TextField
										displayName="language"
										field="language"
										type="text"
										value={lan?.language}
										onChange={(e) => languageSkillHandleOnChange(index, e)}
										placeholder={t("language")}
										validationMessage={
											languageSkillsFieldErrors[index]?.language
										}
									/>
								</Grid>
								<Grid size={{ sm: 4, md: 4, xs: 8 }}>
									<DropdownField
										displayName="niveau"
										field="niveau"
										selected={lan?.niveau}
										onChange={(event) =>
											languageSkillHandleOnChange(index, event)
										}
										enumType={LanguageNiveau}
										validationMessage={languageSkillsFieldErrors[index]?.niveau}
									/>
								</Grid>
								<Grid size={{ sm: 2, md: 2, xs: 4 }} className="deleteBtn">
									<IconButton onClick={() => languageHandleOnRemove(index)}>
										<img src={images.TrashCan} alt="delete" />
									</IconButton>
								</Grid>
								{index < languageSkills.length - 1 && (
									<div className="partition"></div>
								)}
							</Fragment>
						))}
						<Button variant="contained" onClick={setLanguageSkillsHandleOnAdd}>
							{t("add")}
						</Button>
					</Grid>

					<h6>{t("skills")}</h6>
					<Grid
						container
						spacing={{ xs: 3, sm: 4, lg: 5 }}
						className="items-group"
					>
						{skills.map((skill, index) => (
							<Fragment key={`skill-${index}`}>
								<Grid size={{ sm: 6, md: 6, xs: 12 }}>
									<TextField
										displayName="skill"
										field="name"
										type="text"
										value={skill?.name}
										onChange={(e) => skillHandleOnChange(index, e)}
										placeholder={t("skill")}
										validationMessage={skillsFieldErrors[index]?.name}
									/>
								</Grid>
								<Grid size={{ sm: 4, md: 4, xs: 8 }}>
									<DropdownField
										displayName="yearsOfExperience"
										field="years"
										selected={skill?.years}
										onChange={(event) => skillHandleOnChange(index, event)}
										enumType={YearsOfExperience}
										validationMessage={skillsFieldErrors[index]?.years}
									/>
								</Grid>
								<Grid size={{ sm: 2, md: 2, xs: 4 }} className="deleteBtn">
									<IconButton onClick={() => skillHandleOnRemove(index)}>
										<img src={images.TrashCan} alt="delete" />
									</IconButton>
								</Grid>
								{index < skills.length - 1 && <div className="partition"></div>}
							</Fragment>
						))}
						<Button variant="contained" onClick={skillHandleOnAdd}>
							{t("add")}
						</Button>
					</Grid>

					<h6>{t("workExperience")}</h6>
					<Grid
						container
						spacing={{ xs: 3, sm: 4, lg: 5 }}
						className="items-group"
					>
						{workExperiences.map((wx, index) => (
							<Fragment key={`workExperience-${index}`}>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="jobTitle"
										field="jobTitle"
										type="text"
										value={wx?.jobTitle}
										onChange={(e) => experienceHandleOnChange(index, e)}
										placeholder={t("jobTitle")}
										validationMessage={experienceFieldErrors[index]?.jobTitle}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="companyName"
										field="company"
										type="text"
										value={wx?.company}
										onChange={(e) => experienceHandleOnChange(index, e)}
										placeholder={t("companyName")}
										validationMessage={experienceFieldErrors[index]?.company}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="address"
										field="address"
										type="text"
										value={wx?.address}
										onChange={(e) => experienceHandleOnChange(index, e)}
										placeholder={t("address")}
										validationMessage={experienceFieldErrors[index]?.address}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<FormControl>
										<label>{t("from")}</label>
										<DatePicker
											format="DD-MM-YYYY"
											maxDate={dayjs(new Date())}
											value={wx?.startDate ? dayjs(wx?.startDate) : null}
											onChange={(v) =>
												wx &&
												setWorkExperiences(
													workExperiences.map((ex, innerIndex) => {
														if (innerIndex === index) {
															return {
																...ex,
																startDate: v ? v.format("YYYY-MM-DD") : "",
															};
														} else {
															return ex;
														}
													})
												)
											}
										/>
										{experienceFieldErrors[index]?.startDate && (
											<div className="text-danger" style={{ color: "red" }}>
												{experienceFieldErrors[index]?.startDate}
											</div>
										)}
									</FormControl>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<FormControl>
										<label>{t("until")}</label>
										<DatePicker
											format="DD-MM-YYYY"
											maxDate={dayjs(new Date())}
											value={wx?.endDate ? dayjs(wx?.endDate) : null}
											onChange={(v) =>
												wx &&
												setWorkExperiences(
													workExperiences.map((ex, innerIndex) => {
														if (innerIndex === index) {
															return {
																...ex,
																endDate: v ? v.format("YYYY-MM-DD") : "",
															};
														} else {
															return ex;
														}
													})
												)
											}
										/>
										{experienceFieldErrors[index]?.startDate && (
											<div className="text-danger" style={{ color: "red" }}>
												{userDetailFieldErrors.dateOfBirth}
											</div>
										)}
									</FormControl>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }} className="deleteBtn">
									<IconButton onClick={() => experienceHandleOnRemove(index)}>
										<img src={images.TrashCan} alt="delete" />
									</IconButton>
								</Grid>
								{index < workExperiences.length - 1 && (
									<div className="partition"></div>
								)}
							</Fragment>
						))}
						<Button variant="contained" onClick={experienceHandleOnAdd}>
							{t("add")}
						</Button>
					</Grid>
					<h6>{t("education")}</h6>
					<Grid
						container
						spacing={{ xs: 3, sm: 4, lg: 5 }}
						className="items-group"
					>
						{educations.map((education, index) => (
							<Fragment key={`education-${index}`}>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"niveau"}
										field={"academicDegree"}
										selected={education?.academicDegree}
										onChange={(e) => educationHandleOnChange(index, e)}
										enumType={AcademicDegree}
										validationMessage={
											educationFieldErrors[index]?.academicDegree
										}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"fieldOfStudy"}
										field={"fieldOfStudy"}
										selected={education?.fieldOfStudy}
										onChange={(e) => educationHandleOnChange(index, e)}
										enumType={FieldOfStudy}
										validationMessage={
											educationFieldErrors[index]?.fieldOfStudy
										}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="school"
										field="institution"
										type="text"
										value={education?.institution}
										onChange={(e) => educationHandleOnChange(index, e)}
										placeholder={t("school")}
										validationMessage={educationFieldErrors[index]?.institution}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="address"
										field="address"
										type="text"
										value={education?.address}
										onChange={(e) => educationHandleOnChange(index, e)}
										placeholder={t("address")}
										validationMessage={educationFieldErrors[index]?.address}
									/>
								</Grid>
								<Grid size={{ sm: 5, md: 3, xs: 12 }}>
									<FormControl>
										<label>{t("from")}</label>
										<DatePicker
											maxDate={dayjs(new Date())}
											format="DD-MM-YYYY"
											value={
												education?.startDate
													? dayjs(education?.startDate)
													: null
											}
											onChange={(v) =>
												education &&
												setEducations(
													educations.map((ex, innerIndex) => {
														if (innerIndex === index) {
															return {
																...ex,
																startDate: v ? v.format("YYYY-MM-DD") : "",
															};
														} else {
															return ex;
														}
													})
												)
											}
										/>
										{educationFieldErrors[index]?.startDate && (
											<div className="text-danger" style={{ color: "red" }}>
												{educationFieldErrors[index]?.startDate}
											</div>
										)}
									</FormControl>
								</Grid>
								<Grid size={{ sm: 5, md: 3, xs: 12 }}>
									<FormControl>
										<label>{t("until")}</label>
										<DatePicker
											format="DD-MM-YYYY"
											minDate={dayjs(new Date(education.startDate))}
											value={
												education?.endDate ? dayjs(education?.endDate) : null
											}
											onChange={(v) =>
												education &&
												setEducations(
													educations.map((ex, innerIndex) => {
														if (innerIndex === index) {
															return {
																...ex,
																endDate: v ? v.format("YYYY-MM-DD") : "",
															};
														} else {
															return ex;
														}
													})
												)
											}
										/>
										{educationFieldErrors[index]?.endDate && (
											<div className="text-danger" style={{ color: "red" }}>
												{educationFieldErrors[index]?.endDate}
											</div>
										)}
									</FormControl>
								</Grid>
								<Grid size={{ sm: 2, md: 2, xs: 12 }} className="deleteBtn">
									<IconButton onClick={() => educationHandleOnRemove(index)}>
										<img src={images.TrashCan} alt="delete" />
									</IconButton>
								</Grid>
								{index < educations.length - 1 && (
									<div className="partition"></div>
								)}
							</Fragment>
						))}
						<Button variant="contained" onClick={educationHandleOnAdd}>
							{t("add")}
						</Button>
					</Grid>

					<h6>{t("certificateLicences")}</h6>
					<Grid
						container
						spacing={{ xs: 3, sm: 4, lg: 5 }}
						className="items-group"
					>
						{certificates.map((certificate, index) => (
							<Fragment key={`certificateLicences-${index}`}>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="designation"
										field="title"
										type="text"
										value={certificate?.title}
										onChange={(e) => certificateHandleOnChange(index, e)}
										placeholder={t("designation")}
										validationMessage={certificateFieldErrors[index]?.title}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="description"
										field="description"
										type="text"
										value={certificate?.description}
										onChange={(e) => certificateHandleOnChange(index, e)}
										placeholder={t("description")}
										validationMessage={
											certificateFieldErrors[index]?.description
										}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<TextField
										displayName="institute"
										field="institution"
										type="text"
										value={certificate?.institution}
										onChange={(e) => certificateHandleOnChange(index, e)}
										placeholder={t("designation")}
										validationMessage={
											certificateFieldErrors[index]?.institution
										}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<FileUploadField
										displayName="file"
										field="fileName"
										fileName={certificate?.fileName}
										type={FileType.Certificate}
										onChange={(event) =>
											certificateHandleOnChangeForFile(index, event)
										}
										validationMessage={certificateFieldErrors[index]?.fileName}
										filePath={certificate?.filePath}
									/>
								</Grid>
								<Grid size={{ sm: 5, md: 3, xs: 12 }}>
									<FormControl>
										<label>{t("from")}</label>
										<DatePicker
											maxDate={dayjs(new Date())}
											format="DD-MM-YYYY"
											value={
												certificate?.startDate
													? dayjs(certificate?.startDate)
													: null
											}
											onChange={(v) =>
												certificate &&
												setCertificates(
													certificates.map((ex, innerIndex) => {
														if (innerIndex === index) {
															return {
																...ex,
																startDate: v ? v.format("YYYY-MM-DD") : "",
															};
														} else {
															return ex;
														}
													})
												)
											}
										/>
										{certificateFieldErrors[index]?.startDate && (
											<div className="text-danger" style={{ color: "red" }}>
												{certificateFieldErrors[index]?.startDate}
											</div>
										)}
									</FormControl>
								</Grid>
								<Grid size={{ sm: 5, md: 3, xs: 12 }}>
									<FormControl>
										<label>{t("until")}</label>
										<DatePicker
											minDate={dayjs(new Date(certificate.startDate))}
											maxDate={dayjs(new Date())}
											format="DD-MM-YYYY"
											value={
												certificate?.endDate
													? dayjs(certificate?.endDate)
													: null
											}
											onChange={(v) =>
												certificate &&
												setCertificates(
													certificates.map((ex, innerIndex) => {
														if (innerIndex === index) {
															return {
																...ex,
																endDate: v ? v.format("YYYY-MM-DD") : "",
															};
														} else {
															return ex;
														}
													})
												)
											}
										/>
										{certificateFieldErrors[index]?.endDate && (
											<div className="text-danger" style={{ color: "red" }}>
												{certificateFieldErrors[index]?.endDate}
											</div>
										)}
									</FormControl>
								</Grid>
								<Grid size={{ sm: 2, md: 2, xs: 12 }} className="deleteBtn">
									<IconButton onClick={() => certificateHandleOnRemove(index)}>
										<img src={images.TrashCan} alt="delete" />
									</IconButton>
								</Grid>
								{index < certificates.length - 1 && (
									<div className="partition"></div>
								)}
							</Fragment>
						))}
						<Button variant="contained" onClick={certificateHandleOnAdd}>
							{t("add")}
						</Button>
					</Grid>
				</div>
			)}

			{/* my cv end */}

			{/* identical requiter start */}
			{role === UserRoles.Recrewter && (
				<>
					<div className="dream-job card" id="idealCandidate">
						<h6>{t("idealCandidate")}</h6>
						<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerCountry"}
									field={"country"}
									selected={idealCandidate?.country}
									onChange={idealCandidateOnChange}
									enumType={Country}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={
										idealCandidate?.country === Country.Switzerland
											? "headerCanton"
											: "headerState"
									}
									field={"state"}
									index={
										idealCandidate?.country === Country.Germany
											? 8400
											: idealCandidate?.country === Country.Austria
												? 1500
												: 21600 // Default for Switzerland
									}
									indexSize={
										idealCandidate?.country === Country.Germany
											? 17
											: idealCandidate?.country === Country.Austria
												? 10
												: 27 // Default for Switzerland (Canton)
									}
									selected={idealCandidate?.state}
									onChange={idealCandidateOnChange}
									enumType={State}
									disabled={
										idealCandidate?.country === Country.None ||
										!listOfCountriesWithStates.includes(
											idealCandidate?.country!
										)
									}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerEmployementType"}
									field={"employmentType"}
									selected={idealCandidate?.employmentType}
									onChange={idealCandidateOnChange}
									enumType={EmploymentType}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerAvailability"}
									field={"availability"}
									selected={idealCandidate?.availability}
									onChange={idealCandidateOnChange}
									enumType={Availability}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerPosition"}
									field={"jobPosition"}
									selected={idealCandidate?.jobPosition}
									onChange={idealCandidateOnChange}
									enumType={JobPosition}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerPensum"}
									field={"workload"}
									selected={idealCandidate?.workload}
									onChange={idealCandidateOnChange}
									enumType={Workload}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerSalary"}
									field={"salary"}
									selected={idealCandidate?.salary}
									onChange={idealCandidateOnChange}
									enumType={Salary}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"division"}
									field={"division"}
									selected={idealCandidate?.division}
									onChange={idealCandidateOnChange}
									enumType={MedicalDivision}
								/>
							</Grid>
							<Grid size={{ sm: 6, md: 4, xs: 12 }}>
								<DropdownField
									displayName={"headerEducationLevel"}
									field={"educationLevel"}
									selected={idealCandidate?.educationLevel}
									onChange={idealCandidateOnChange}
									enumType={EducationLevel}
								/>
							</Grid>
						</Grid>
					</div>
				</>
			)}
			{/* identical requiter end */}

			<div className="btn-group">
				{profileCompleted && (
					<Button variant="outlined" title={t("cancel")} onClick={handleCancel}>
						{t("cancel")}
					</Button>
				)}
				<Button variant="contained" title={t("save")} onClick={finalSubmit}>
					{t("save")}
				</Button>
			</div>
		</>
	);
};
