import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
export default function ProgressWithToolTip(
	props: ProgressWithToolTipProperties
) {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 15,
		borderRadius: 10,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[200],
			...theme.applyStyles("dark", {
				backgroundColor: theme.palette.grey[800],
			}),
		},
	}));
	const MIN = 0;
	const MAX = 100;
	const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);
	return (
		<div className="progressWithToolTip">
			{props.title ? <p>{props.title}</p> : null}
			<div
				className="progressWithToolTipToolTip"
				style={{ paddingLeft: `calc(${props.value}% - 25px)` }}
			>
				<div>{props.toolTip}</div>
			</div>
			<BorderLinearProgress
				variant="determinate"
				value={normalise(props.value)}
			/>
		</div>
	);
}

interface ProgressWithToolTipProperties {
	value: number;
	title?: string;
	toolTip: string;
}
