import { Button, Switch } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import {
	Availability,
	EmploymentType,
	JobPosition,
	Salary,
	UserRoles,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import {
	JobType,
	MedicalDivision,
} from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import {
	getCompanyDetailsByJobOfferAPI,
	getCompanyLogoAPI,
} from "../../core/apiFunctions";
import { checkRole } from "../../core/claimFunctions";
import {
	extendedJobOfferDTO,
	externalJobOfferDTO,
	jobApplicationDTO,
} from "../../core/dto/dto.models";
import { enumToString } from "../../core/helperConst";
import ModalContent from "../../utils/ModalContent";

export const InternalJobCard = ({
	jobs,
	onClickJob,
	bookmarkClick,
	isBookmark,
	jobType,
	handleToggleApplicationHidden,
	handleApplicationDelete,
}: {
	jobs: extendedJobOfferDTO | jobApplicationDTO;
	jobType: "Internal" | "External" | "Application";
	onClickJob: (
		data:
			| {
					jobType: "Internal";
					jobData: extendedJobOfferDTO;
			  }
			| {
					jobType: "External";
					jobData: externalJobOfferDTO;
			  }
	) => void;
	bookmarkClick: (id: string) => void;
	isBookmark: boolean;
	handleToggleApplicationHidden?: (jobUrl: string | undefined) => void;
	handleApplicationDelete?: (jobUrl: string) => void;
}) => {
	const { t } = useTranslation();
	const [companyLogo, setCompanyLogo] = useState<string>();
	const { claims } = useContext(AuthenticationContext);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [jobToDelete, setJobToDelete] = useState<jobApplicationDTO | null>(
		null
	);
	// To get the company logo
	useEffect(() => {
		jobType === "Internal" &&
			getCompanyDetailsByJobOfferAPI(jobs.id).then((res) => {
				getCompanyLogoAPI(res.data.id).then((response) => {
					const url = URL.createObjectURL(response.data);
					setCompanyLogo(url);
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobType]);

	return (
		<li>
			<div
				className="card"
				onClick={() =>
					jobType === "Internal" &&
					onClickJob({
						jobType: "Internal",
						jobData: jobs as extendedJobOfferDTO,
					})
				}
			>
				<div className="job-title">
					<div className="left">
						<img
							src={companyLogo || images.MyCompany}
							alt="Company Logo"
							className={jobType === "External" ? "" : "internal-job-logo"}
						/>
						<div className="content">
							<p>{jobs.title}</p>
						</div>
					</div>
					{/* <IconButton
						title="Bookmark"
						className={isBookmark ? "active" : ""}
						onClick={(e) => {
							e.stopPropagation();
							bookmarkClick(jobs.id || "");
						}}
					>
						<img
							src={isBookmark ? images.BookmarkActive : images.Bookmark}
							alt="Bookmark"
						/>
					</IconButton> */}
				</div>
				<p
					className="job-description"
					dangerouslySetInnerHTML={{
						__html: jobs.description ?? "",
					}}
				></p>

				<Grid container spacing={0} className="job-details">
					{checkRole(claims, UserRoles.Recrewter) ? (
						<>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{jobs.isPublished ? t("published") : t("hidden")}</small>
								<Switch
									checked={jobs.isPublished === true}
									className="switch"
									onClick={(e) => {
										e.stopPropagation();
									}}
									onChange={() =>
										handleToggleApplicationHidden?.(
											(jobs as jobApplicationDTO).url
										)
									}
									color="primary"
									id="flexSwitchCheckDefault"
								/>
							</Grid>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{t("created")}</small>
								<span>{new Date(jobs.createdDate!).toLocaleDateString()}</span>
							</Grid>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{t("workLoad")}</small>
								<span>{t(enumToString(Workload, jobs.workload))}</span>
							</Grid>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<Button
									variant="contained"
									color="error"
									onClick={(e) => {
										e.stopPropagation();
										setShowDeleteModal(true);
										setJobToDelete(jobs as jobApplicationDTO);
									}}
								>
									{t("delete")}
								</Button>
							</Grid>
						</>
					) : (
						<>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{t("medicalDivision")}</small>
								<span>{t(enumToString(MedicalDivision, jobs.division))}</span>
							</Grid>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{t("jobType")}</small>
								<span>{t(enumToString(JobType, jobs.jobType))}</span>
							</Grid>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{t("jobPosition")}</small>
								<span>{t(enumToString(JobPosition, jobs.jobPosition))}</span>
							</Grid>
							<Grid size={{ xs: 12, sm: 6, md: 3 }}>
								<small>{t("workLoad")}</small>
								<span>{t(enumToString(Workload, jobs.workload))}</span>
							</Grid>
						</>
					)}
				</Grid>
				{jobs.employmentType &&
					jobs.country &&
					jobs.salary &&
					jobs.availability &&
					checkRole(claims, UserRoles.Employee) && (
						<ul className="job-summary">
							<li>
								<img src={images.Jobs} alt="Jobs" />
								<span>
									{t(enumToString(EmploymentType, jobs.employmentType))}
								</span>
							</li>
							<li>
								<img src={images.LocationGrey} alt="Location Grey" />
								<span>
									{jobs.state ? `${t(enumToString(State, jobs.state))},` : ""}
									{t(enumToString(Country, jobs.country))}
								</span>
							</li>
							<li>
								<img src={images.Coin} alt="Coin" />
								<span>{t(enumToString(Salary, jobs.salary))}</span>
							</li>
							<li>
								<img src={images.Clock} alt="Clock" />
								<span>{t(enumToString(Availability, jobs.availability))}</span>
							</li>
						</ul>
					)}
				{jobs.country && checkRole(claims, UserRoles.Recrewter) && (
					<ul className="job-summary">
						<li>
							<img src={images.LocationGrey} alt="Location Grey" />
							<span>
								{jobs.state ? `${t(enumToString(State, jobs.state))}, ` : ""}
								{t(enumToString(Country, jobs.country))}
							</span>
						</li>
						{/* <li>
							<img src={images.MyCV} alt="Coin" />
							<span>{(jobs as jobApplicationDTO).appliedCount}</span>
						</li> */}
					</ul>
				)}
			</div>
			<ModalContent
				title={t("confirmation")}
				size="sm"
				show={showDeleteModal}
				onHideCallbackSecondary={() => setShowDeleteModal(false)}
				onHideCallbackPrimary={() => {
					handleApplicationDelete?.(jobToDelete?.url || "");
					setShowDeleteModal(false);
					setJobToDelete(null);
				}}
				onClose={() => {
					setShowDeleteModal(false);
					setJobToDelete(null);
				}}
				primaryButton={t("delete")}
				secondaryButton={t("cancel")}
			>
				<p>{t("deleteJobOfferAlertMsg")}</p>
			</ModalContent>
		</li>
	);
};
