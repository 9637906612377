import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Button } from "@mui/material";
import FormikTextField from "../common/FormikTextField";
import DisplayErrors from "../common/DisplayErrors";
import useFeedback from "../../utils/useFeedback";
import { requestPasswordResetAPI } from "../../core/apiFunctions";
import ModalContent from "../../utils/ModalContent";
import { FeedbackMode } from "../../config/Types/GeneralEnumDefinitions";

interface ForgotPasswordContentProps {
	handleClose: () => void;
	isOpen: boolean;
}

const ForgotPasswordContent: React.FC<ForgotPasswordContentProps> = ({
	handleClose,
	isOpen,
}) => {
	const { t } = useTranslation();
	const [errors, setErrors] = useState<string[]>([]);
	const { showSuccess } = useFeedback();

	const validationSchema = yup.object({
		email: yup.string().required(t("emailRequired")).email(t("emailNotValid")),
	});

	const submitPasswordReset = async (values: { email: string }) => {
		try {
			setErrors([]);
			const response = await requestPasswordResetAPI(values.email);
			if (response.status === 200) {
				handleClose();
				showSuccess(t("passwordResetRequested"), FeedbackMode.Success, 10000);
			} else {
				setErrors(response.data);
			}
		} catch (error: any) {
			if (error && error.response) {
				setErrors([error.response.data || t("requestFailed")]);
			} else {
				setErrors([t("requestFailed")]);
			}
		}
	};

	return (
		<ModalContent
			title={t("forgotPassword")}
			size="sm"
			show={isOpen}
			onClose={handleClose}
		>
			<Formik
				initialValues={{ email: "" }}
				onSubmit={submitPasswordReset}
				validationSchema={validationSchema}
				validateOnBlur={false}
			>
				{() => (
					<Form>
						<FormikTextField
							displayName={t("email")}
							field="email"
							type="text"
							autoFocus={true}
						/>
						<DisplayErrors errors={errors} />
						<div
							className="buttons"
							style={{
								display: "flex",
								gap: "16px",
								padding: "16px 8px 8px 0",
								justifyContent: "flex-end",
							}}
						>
							<Button variant="contained" type="submit">
								{t("requestPassword")}
							</Button>
							<Button variant="outlined" onClick={handleClose}>
								{t("cancel")}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</ModalContent>
	);
};

export default ForgotPasswordContent;
