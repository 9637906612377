import { useTranslation } from "react-i18next";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import { useContext, useState } from "react";
import AuthenticationContext from "../auth/AuthenticationContext";
import { getRoleId } from "../../core/claimFunctions";
import {
	deleteUserAPI,
	getUserDetailsAPI,
	submitUserDetailsAPI,
} from "../../core/apiFunctions";
import { logout } from "../../core/handleJWT";
import ModalContent from "../../utils/ModalContent";
import Emitter from "../../utils/emitter";

type Props = {
	isOpen: boolean;
	handleClose: () => void;
};

const DeleteAccount: React.FC<Props> = ({ isOpen, handleClose }) => {
	const { t } = useTranslation();
	const { claims } = useContext(AuthenticationContext);
	const [role] = useState<UserRoles>(getRoleId(claims) ?? 0);

	const handleDelete = () => {
		deleteUserAPI().then(() => logout());
	};

	const handleHide = async () => {
		const response = await getUserDetailsAPI();
		response.data.isPublic = false;
		await submitUserDetailsAPI(response.data);
		handleClose();
		Emitter.emit("toggleProfileVisibility", {});
	};

	return (
		<>
			<ModalContent
				title={t("deleteMyAccount")}
				size="md"
				show={isOpen}
				onClose={handleClose}
				primaryButton={t("delete")}
				secondaryButton={role === UserRoles.Employee ? t("hide") : null}
				secondaryButtonColor="warning"
				thirdButton={t("cancel")}
				onHideCallbackPrimary={handleDelete}
				onHideCallbackSecondary={handleHide}
				onHideCallbackThird={handleClose}
			>
				<div style={{ padding: "8px" }}>
					{role === UserRoles.Employee
						? t("deleteMyAccountWarningEmployee")
						: t("deleteMyAccountWarning")}
				</div>
			</ModalContent>
		</>
	);
};

export default DeleteAccount;
