import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import {
	AcademicDegree,
	Availability,
	EducationLevel,
	EmploymentType,
	FieldOfStudy,
	JobPosition,
	Salary,
	UserRoles,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { getRoleId } from "../../core/claimFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import {
	aboutMeDTO,
	certificateDTO,
	educationDTO,
	idealCandidateDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	workExperienceDTO,
} from "../../core/dto/user.models";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import { Link } from "@mui/material";
import images from "../../assets/images";
type Props = {
	userDetails: userDetailsDTO | undefined;
	dreamJob: internalJobDTO;
	aboutMe: aboutMeDTO | undefined;
	cvVideo: string | undefined;
	languageSkills: languageSkillDTO[];
	skills: skillDTO[];
	idealCandidate: idealCandidateDTO;
	certificates: certificateDTO[];
	educations: educationDTO[];
	workExperiences: workExperienceDTO[];
};
export const ViewProfile = ({
	dreamJob,
	userDetails,
	aboutMe,
	languageSkills,
	skills,
	cvVideo,
	idealCandidate,
	certificates,
	educations,
	workExperiences,
}: Props) => {
	const { claims } = useContext(AuthenticationContext);
	const { t } = useTranslation();

	const [role] = useState<UserRoles>(getRoleId(claims) ?? 0);
	function formatDate(dateString: string) {
		const date = new Date(dateString);
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		return date.toLocaleDateString("en-US", options);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{/* basic information start*/}
			<div className="basic-information card" id="basicInformation">
				<h6>{t("basicInformation")}</h6>
				<Grid container spacing={{ xs: 4 }}>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("fullName")}:</span>
							<p>{`${userDetails?.firstName} ${userDetails?.lastName}`}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("birthday")}:</span>
							<p>{formatDate(userDetails?.dateOfBirth)}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("nationality")}:</span>
							<p>
								{isNaN(userDetails?.nationality)
									? t(userDetails?.nationality)
									: t(enumToString(Country, Number(userDetails?.nationality)))}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>
								{userDetails?.nationality === Country.Switzerland
									? t("canton")
									: t("state")}
								:
							</span>
							<p>{t(enumToString(State, userDetails?.state) || "-")}</p>
						</div>
					</Grid>
					{!(Country.Switzerland.toString() === userDetails?.nationality) && (
						<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
							<div className="info-detail">
								<span>{t("residencePermit")}:</span>
								<p>{userDetails?.residencePermit}</p>
							</div>
						</Grid>
					)}
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("phoneNumber")}:</span>
							<p>
								{userDetails?.phoneNumber === ""
									? "-"
									: userDetails?.phoneNumber}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("mobileNumber")}:</span>
							<p>
								{userDetails?.mobileNumber === ""
									? "-"
									: userDetails?.mobileNumber}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("email")}:</span>
							<p>
								{userDetails?.email ? (
									<Link href={`mailto:${userDetails.email}`} target="_blank">
										{userDetails.email}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("website")}:</span>
							<p>
								{userDetails?.website ? (
									<Link
										target="_blank"
										href={formatUrlLink(userDetails?.website)}
									>
										{userDetails.website}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("linkedin")}:</span>
							<p>
								{userDetails?.linkedIn ? (
									<Link
										target="_blank"
										href={formatUrlLink(userDetails?.linkedIn)}
									>
										{userDetails.linkedIn}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>

					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("xing")}: </span>
							<p>
								{userDetails?.xing ? (
									<Link target="_blank" href={formatUrlLink(userDetails?.xing)}>
										{userDetails.xing}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
				</Grid>
			</div>
			{/* basic information end*/}

			{role === UserRoles.Employee && (
				<>
					{/* job description start */}
					<div className="job-description card" id="myDreamJob">
						<h6>{t("myDreamJobDescription")}</h6>
						<p>{dreamJob?.description}</p>
					</div>
					{/* job description end */}
					{/* dream job start */}
					<div className="dream-job card">
						<h6>{t("myDreamJob")}</h6>
						<Grid container spacing={{ xs: 4 }}>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("country")}:</span>
									<p>{t(enumToString(Country, dreamJob?.country ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>
										{dreamJob?.country === Country.Switzerland
											? t("canton")
											: t("state")}
										:
									</span>
									<p>{t(enumToString(State, dreamJob?.state ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerEmployementType")}:</span>
									<p>
										{t(
											enumToString(
												EmploymentType,
												dreamJob?.employmentType ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerAvailability")}:</span>
									<p>
										{t(enumToString(Availability, dreamJob?.availability ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("position")}:</span>
									<p>
										{t(enumToString(JobPosition, dreamJob?.jobPosition ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("workLoad")} %:</span>
									<p>{t(enumToString(Workload, dreamJob?.workload ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerSalary")}:</span>
									<p>{t(enumToString(Salary, dreamJob?.salary ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("division")}:</span>
									<p>
										{t(enumToString(MedicalDivision, dreamJob?.division ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerEducationLevel")}:</span>
									<p>
										{t(
											enumToString(
												EducationLevel,
												dreamJob?.educationLevel ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
						</Grid>
					</div>
					{/* dream job end */}

					{/* my cv start */}

					<div className="card my-cv" id="myCv">
						<h6>{t("myCv")}</h6>
						<p>{t("aboutMe")}</p>
						<span>{aboutMe?.aboutMe}</span>

						{/* {cvVideo && (
							<div
								style={{
									overflow: "hidden",
								}}
							>
								<video
									className="VideoInput_video mb-2"
									width="400px"
									controls
									src={cvVideo}
									style={{
										padding: " 0px 10px 15px 0px",
										maxHeight: "300px",
									}}
								/>
							</div>
						)} */}

						<p>{t("languageSkills")}</p>
						<div className="chips-group">
							{languageSkills.map((lang) => (
								<Chip key={lang.id} label={lang.language} variant="outlined" />
							))}
						</div>
						<p>{t("skills")}</p>
						<div className="chips-group">
							{skills.map((skill) => (
								<Chip label={skill.name} key={skill?.id} variant="outlined" />
							))}
						</div>
						<p>{t("workExperience")}</p>
						<div className="professional-experience view">
							<ul>
								{workExperiences.map((workExperience) => (
									<li key={workExperience.id}>
										<div className="icon">
											<img src={images.JobsWhite} alt="Jobs" />
										</div>
										<div className="details">
											<p>{workExperience.jobTitle}</p>
											<span>{workExperience.company}</span>
											<small>{`${formatDate(
												workExperience.startDate
											)} - ${formatDate(workExperience.endDate)} ${workExperience.address ? `| ${workExperience.address}` : ""}`}</small>
										</div>
									</li>
								))}
							</ul>
						</div>
						<p>{t("education")}</p>
						<div className="education view">
							<ul>
								{educations.map((education) => (
									<li key={education.id}>
										<div className="icon">
											<img src={images.Education} alt="Education" />
										</div>
										<div className="details">
											<p>
												{t(
													enumToString(
														AcademicDegree,
														education.academicDegree ?? 0
													)
												)}
											</p>
											<span>{`${education.institution} ${
												education.fieldOfStudy
													? `| ${t(
															enumToString(
																FieldOfStudy,
																education.fieldOfStudy ?? 0
															)
														)}`
													: ""
											}`}</span>
											<small>{`${formatDate(
												education.startDate
											)} - ${formatDate(education.endDate)} ${education.address ? `| ${education.address}` : ""}`}</small>
										</div>
									</li>
								))}
							</ul>
						</div>
						<p>{t("certificateLicences")}</p>
						<div className="certificate view">
							<ul>
								{certificates.map((certificate) => (
									<li key={certificate.id}>
										<div className="icon">
											<img src={images.CertificateWhite} alt="certificate" />
										</div>
										<div className="details">
											<p>{certificate.title}</p>
											<span>{certificate.description}</span>
											<small>
												{`${formatDate(certificate.startDate)} - ${formatDate(
													certificate.endDate
												)} ${certificate.institution ? `| ${certificate.institution}` : ""}`}
											</small>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
					{/* my cv end */}
				</>
			)}

			{role === UserRoles.Recrewter && (
				<>
					{/* dream job start */}
					<div className="dream-job card" id="idealCandidate">
						<h6>{t("idealCandidate")}</h6>
						<Grid container spacing={{ xs: 4 }}>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("country")}:</span>
									<p>
										{t(enumToString(Country, idealCandidate?.country ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>
										{idealCandidate?.country === Country.Switzerland
											? t("canton")
											: t("state")}
										:
									</span>
									<p>{t(enumToString(State, idealCandidate?.state ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerEmployementType")}:</span>
									<p>
										{t(
											enumToString(
												EmploymentType,
												idealCandidate?.employmentType ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerAvailability")}:</span>
									<p>
										{t(
											enumToString(
												Availability,
												idealCandidate?.availability ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("position")}:</span>
									<p>
										{t(
											enumToString(
												JobPosition,
												idealCandidate?.jobPosition ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("workLoad")} %:</span>
									<p>
										{t(enumToString(Workload, idealCandidate?.workload ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerSalary")}:</span>
									<p>{t(enumToString(Salary, idealCandidate?.salary ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("division")}:</span>
									<p>
										{t(
											enumToString(
												MedicalDivision,
												idealCandidate?.division ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerEducationLevel")}:</span>
									<p>
										{t(
											enumToString(
												EducationLevel,
												idealCandidate?.educationLevel ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
						</Grid>
					</div>
					{/* dream job end */}
				</>
			)}
		</>
	);
};
