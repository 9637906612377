/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { chatDTO, messageDTO } from "../../../core/dto/dto.models";
import images from "../../../assets/images";
import { getClaims } from "../../../core/handleJWT";
import {
	getChatsAPI,
	getCompanyNameForRecrewterAPI,
	getNewChatAPI,
	getProfilePhotoAPI,
	getUserInformationAPI,
	markChatAsReadAPI,
	removeLastMessageAPI,
	requestNewUserConnectionAPI,
	sendMessageAPI,
	respondUserConnectionAPI,
} from "../../../core/apiFunctions";
import { getName, getNameIdentifier } from "../../../core/claimFunctions";
import { useSetState } from "../../../utils/UseSetState";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthenticationContext from "../../../components/auth/AuthenticationContext";
import { ConnectionStatus } from "../../../config/Types/GeneralEnumDefinitions";
import { cyrb53Hash } from "../../../core/helperFunctions";
import WebSocketContext from "../../../contexts/WebSocketContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import "./TempChat.scss";

export default function TempChat() {
	// translation
	const { t } = useTranslation();

	// navigation
	let navigate = useNavigate();

	const { claims } = useContext(AuthenticationContext);

	const location = useLocation();
	// params for new chat or/and prefill message
	const { participantId, contentForMessage } = useParams<{
		participantId: string;
		contentForMessage: string;
	}>();
	// list of open chats
	const [chats, setChats, getChat] = useSetState<chatDTO[]>([]);
	// selected chat
	const [selectedChat, setSelectedChat] = useState<chatDTO | null>(null);
	// message input for new message
	const [messageField, setMessageField] = useState("");
	// get own nameidentifier
	const [myId] = useState<string | null>(getNameIdentifier(getClaims()));
	// ref to chat window for scrolling to bottom
	const chatContainerRef = useRef<HTMLDivElement>(null);
	// get own profile picture from localstorage
	const [myProfilePicture] = useState<string | null>(
		localStorage.getItem("profile-picture")
	);

	const {
		connection,
		messageReceivedSignalR,
		messageReadSignalR,
		removeLastMessageSignalR,
		setMessageReceivedSignalR,
		setMessageReadSignalR,
		setRemoveLastMessageSignalR,
	} = useContext(WebSocketContext)!;

	function correctMessage(messages: string) {
		let messageCorrected = messages.replace(new RegExp("%20", "g"), " ");
		messageCorrected = messageCorrected.replace(new RegExp("%22", "g"), '"');
		messageCorrected = messageCorrected.replace(new RegExp("%28", "g"), "(");
		messageCorrected = messageCorrected.replace(new RegExp("%29", "g"), ")");
		messageCorrected = messageCorrected.replace(new RegExp("%2C", "g"), ",");
		messageCorrected = messageCorrected.replace(new RegExp("%3A", "g"), ":");
		messageCorrected = messageCorrected.replace(new RegExp("%3B", "g"), ";");
		messageCorrected = messageCorrected.replace(new RegExp("%2D", "g"), "-");
		messageCorrected = messageCorrected.replace(new RegExp("%2F", "g"), "/");
		messageCorrected = messageCorrected.replace(new RegExp("%3F", "g"), "?");
		messageCorrected = messageCorrected.replace(new RegExp("%5B", "g"), "[");
		messageCorrected = messageCorrected.replace(new RegExp("%5D", "g"), "]");
		messageCorrected = messageCorrected.replace(new RegExp("%5C", "g"), "\\");
		messageCorrected = messageCorrected.replace(new RegExp("%7B", "g"), "{");
		messageCorrected = messageCorrected.replace(new RegExp("%7D", "g"), "}");
		messageCorrected = messageCorrected.replace(new RegExp("%7C", "g"), "|");
		messageCorrected = messageCorrected.replace(new RegExp("%7E", "g"), "~");
		messageCorrected = messageCorrected.replace(new RegExp("%60", "g"), "`");
		messageCorrected = messageCorrected.replace(new RegExp("%21", "g"), "!");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%A4", "g"), "ä");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%BC", "g"), "ü");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%B6", "g"), "ö");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%84", "g"), "Ä");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%9C", "g"), "Ü");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%96", "g"), "Ö");
		messageCorrected = messageCorrected.replace(new RegExp("%C3%9F", "g"), "ß");

		return messageCorrected;
	}

	useEffect(() => {
		// check if new chat is requested and get all existing chats
		if (participantId) {
			getNewChatAPI(participantId).then(() => {
				getChatsAPI().then((response) => {
					setChats(response.data);
					const chat = response.data.find((c) =>
						c.participants.find((p) => p.id === participantId)
					);
					// set preselected chat if set
					if (chat) {
						setSelectedChat(chat);
						// set predefined message if set
						if (contentForMessage) {
							setMessageField(correctMessage(contentForMessage));
						}
					}
				});
			});
		} else {
			getChatsAPI().then((response) => {
				setChats(response.data);
				// If no chat is selected and there are chats available, set the first chat as selected
				if (!selectedChat && response.data.length > 0) {
					setSelectedChat(response.data[0]);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	// handle signalR when my message has been read by participant
	useEffect(() => {
		if (messageReadSignalR) {
			markMyMessageAsRead(messageReadSignalR);
			// set myMessageHasBeenReadInChat as undefined
			setMessageReadSignalR("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageReadSignalR]);

	// handle signalR new message incoming
	useEffect(() => {
		if (messageReceivedSignalR != null) {
			receiveMessage(messageReceivedSignalR);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageReceivedSignalR]);

	// handle signalR message to remove last message
	useEffect(() => {
		if (removeLastMessageSignalR) {
			undoParticipantsLastMessage(removeLastMessageSignalR);
			setRemoveLastMessageSignalR("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeLastMessageSignalR]);

	useEffect(() => {
		// scroll to bottom of chat window
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTo(
				0,
				chatContainerRef.current.scrollHeight
			);
		}

		markParticipantMessageAsRead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chats, selectedChat]);

	const receiveMessage = async (message: messageDTO) => {
		// add message to chat
		const chats = await getChat();
		const chat = chats.find((c) => c.id === message.chatId);
		if (chat) {
			message.date = new Date(message.date);
			chat.messages.push(message);
			setChats([...chats]);
		}
	};

	const markMyMessageAsRead = async (chatId: string) => {
		// get chat and mark all my messages as read
		const chat = chats.find((c) => c.id === chatId);
		if (chat) {
			chat.messages.forEach((m) => {
				if (m.senderId === myId) {
					m.isRead = true;
				}
			});
			setChats([...chats]);
		}
	};

	const markParticipantMessageAsRead = () => {
		// mark chat as read if any message from other participant is unread
		if (
			selectedChat &&
			selectedChat.messages.filter((m) => m.senderId !== myId && !m.isRead)
				.length > 0
		) {
			// mark chat as read in database
			markChatAsReadAPI(selectedChat.id);

			// send read message to other participant
			if (connection) {
				const participantId = selectedChat.participants.find(
					(p) => p.id !== myId
				)?.id;
				connection.invoke("MarkChatAsRead", participantId, selectedChat.id);
			}

			selectedChat.messages.forEach((m) => {
				if (m.senderId !== myId) {
					m.isRead = true;
				}
			});
			setChats([...chats]);
		}
	};

	const send = (message?: string | null) => {
		const messageDTO: messageDTO = {
			chatId: selectedChat!.id,
			senderId: myId!,
			content: message ?? messageField,
			isRead: false,
			date: new Date(),
		};

		sendMessageAPI(messageDTO, selectedChat!.id).then(() => {
			if (connection) {
				const participantId = selectedChat!.participants.find(
					(p) => p.id !== myId
				)?.id;
				connection.invoke("SendMessage", participantId, messageDTO);
			}

			// add message to chat
			const chat = chats.find((c) => c.id === selectedChat!.id);
			if (chat) {
				chat.messages.push(messageDTO);
				setChats([...chats]);
			}

			// clear message field
			setMessageField("");
		});
	};

	const undoParticipantsLastMessage = (chatId: string) => {
		const chat = chats.find((c) => c.id === chatId);
		if (chat) {
			chat.messages.pop();
			setChats([...chats]);
		}
	};

	const undoMyLastMessage = () => {
		if (selectedChat) {
			removeLastMessageAPI(selectedChat.id).then(() => {
				selectedChat.messages.pop();
				setChats([...chats]);

				// notify other participant that message has been removed
				if (connection) {
					const participantId = selectedChat!.participants.find(
						(p) => p.id !== myId
					)?.id;
					connection.invoke(
						"RemoveLastMessage",
						participantId,
						selectedChat.id
					);
				}
			});
		}
	};

	const profileNameWrapper = (participantId: string) => {
		// if participant is me return my name
		if (participantId === myId) {
			return getName(claims);
		}

		// get participant from id in all chats
		const chat = chats.find((c) =>
			c.participants.find((p) => p.id === participantId)
		);
		const participant = chat?.participants.find((p) => p.id === participantId);

		// if participant is found return name
		if (participant) {
			// check if participant is Recruiter
			if (participant.isRecrewter && participant.companyName === undefined) {
				// Check if the name fetch is already in progress
				if (!participant.fetchingName) {
					// Mark that the fetch is in progress
					participant.fetchingName = true;
					getCompanyNameForRecrewterAPI(participantId).then((response) => {
						participant.companyName = response.data;
						setChats([...chats]);
						participant.fetchingName = false;
					});
				}
			}

			// check if participant name is already loaded
			if (participant.name) {
				return participant.isRecrewter
					? `${participant.name} (${participant.companyName})`
					: participant.name;
			}

			if (!participant.fetchingName) {
				// Mark that the fetch is in progress
				participant.fetchingName = true;
				getUserInformationAPI(participantId).then((response) => {
					participant.name = response.data.name;
					// Update the UI
					setChats([...chats]);
					participant.fetchingName = false;
				});
			}
		}
	};

	const profilePhotoWrapper = (participantId: string) => {
		// if participant is me return my profile picture
		if (participantId === myId) {
			return myProfilePicture;
		}

		// get participant from id in all chats
		const chatsCopy = [...chats];
		const participant = chatsCopy
			.find((c) => c.participants.find((p) => p.id === participantId))
			?.participants.find((p) => p.id === participantId);

		// if participant is found return profile picture
		if (participant) {
			// check if participant photo is already loaded
			if (participant.photoUrl) {
				return participant.photoUrl;
			}
			// Check if the photo fetch is already in progress
			if (!participant.fetchingPhoto) {
				// Mark that the fetch is in progress
				participant.fetchingPhoto = true;

				// Fetch profile picture from server
				getProfilePhotoAPI(participantId).then((response) => {
					if (response && response.data !== null) {
						const url = URL.createObjectURL(response.data);
						participant.photoUrl = url;
						participant.fetchingPhoto = false;
						setChats([...chatsCopy]);
						return url;
					}
				});
			}
		}
	};

	const incomingChats = () => {
		// chats which do not contain any message from me and contain at least one message from other participant
		return chats.filter(
			(c) =>
				c.messages.filter((m) => m.senderId === myId).length === 0 &&
				c.messages.filter((m) => m.senderId !== myId).length > 0
		);
	};

	// get all chats where I have been answered
	const openChats = () => {
		// all chats minus new chats minus incoming chats
		return chats.filter(
			(c) =>
				c.messages.length > 0 &&
				!newChats().find((nc) => nc.id === c.id) &&
				!incomingChats().find((ic) => ic.id === c.id)
		);
	};

	const newChats = () => {
		// chats which have no messages at all
		return chats.filter((c) => c.messages.length === 0);
	};

	const countUnreadMessages = (chat: chatDTO) => {
		// count unread messages in chat
		return chat.messages.filter((m) => m.senderId !== myId && !m.isRead).length;
	};

	return (
		<div className="tempchat">
			{(openChats && openChats().length > 0) ||
			(incomingChats && incomingChats().length > 0) ||
			participantId ? (
				<>
					{/* Left Section: Chat List */}
					<div className="tempchat__list">
						{/* Incoming Chats */}
						{incomingChats().length > 0 && (
							<>
								<h3>{t("incomingChats")}</h3>
								{incomingChats().map((chat, index) => (
									<div
										className={`chat-item ${
											chat.id === selectedChat?.id ? "selected" : ""
										}`}
										key={index}
										onClick={() => setSelectedChat(chat)}
									>
										<img
											className="profile-image"
											src={
												profilePhotoWrapper(
													chat.participants.find((p) => p.id !== myId)!.id
												)!
											}
											alt="profile"
										/>
										<p className="profile-name">
											{profileNameWrapper(
												chat.participants.find((p) => p.id !== myId)!.id
											)}
										</p>
									</div>
								))}
							</>
						)}

						{/* Open Chats */}
						{openChats().length > 0 && (
							<>
								<h3>{t("openChats")}</h3>
								{openChats().map((chat, index) => (
									<div
										className={`chat-item ${
											chat.id === selectedChat?.id ? "selected" : ""
										}`}
										key={index}
										onClick={() => setSelectedChat(chat)}
									>
										<img
											className="profile-image"
											src={
												profilePhotoWrapper(
													chat.participants.find((p) => p.id !== myId)!.id
												)!
											}
											alt="profile"
										/>
										<p className="profile-name">
											{profileNameWrapper(
												chat.participants.find((p) => p.id !== myId)!.id
											)}
										</p>
										{countUnreadMessages(chat) > 0 && (
											<p className="unread-count">
												{countUnreadMessages(chat)}
											</p>
										)}
									</div>
								))}
							</>
						)}
					</div>

					{/* Right Section: Chat Window */}
					<div className="tempchat__window">
						{/* Chat Header */}
						{selectedChat ? (
							<>
								<div className="chat-header">
									<h3>
										{t("chat.with")}{" "}
										{profileNameWrapper(
											selectedChat.participants.find((p) => p.id !== myId)!.id
										)}
									</h3>
									<div className="button-group">
										{/* Button to send outgoing request */}
										{selectedChat.participants[0].connectionStatus ===
											ConnectionStatus.None &&
											!selectedChat.participants[0].isRecrewter && (
												<OverlayTrigger
													placement="bottom"
													delay={{ show: 200, hide: 100 }}
													overlay={
														<Tooltip
															id="button-tooltip"
															className="custom-tooltip"
														>
															{t("descriptionRequestConnection")}
														</Tooltip>
													}
												>
													<button
														onClick={async () => {
															await requestNewUserConnectionAPI(
																selectedChat.participants[0].id
															);
															selectedChat.participants[0].connectionStatus =
																ConnectionStatus.Requested;
															setChats([...chats]);

															send(t("requestEntireProfile"));
														}}
													>
														{t("requestConnection")}
													</button>
												</OverlayTrigger>
											)}

										{/* Button to undo outgoing request */}
										{selectedChat.participants[0].connectionStatus ===
											ConnectionStatus.Requested &&
											!selectedChat.participants[0].isRecrewter && (
												<button
													onClick={async () => {
														await respondUserConnectionAPI(
															selectedChat.participants[0].id,
															ConnectionStatus.Declined
														);
														selectedChat.participants[0].connectionStatus =
															ConnectionStatus.None;
														setChats([...chats]);
													}}
												>
													{t("undoRequestConnection")}
												</button>
											)}

										{/* Profile Button */}
										{selectedChat.participants[0].companyName !==
											"Administrator" &&
											profileNameWrapper(selectedChat.participants[0].id) && (
												<OverlayTrigger
													placement="bottom"
													delay={{ show: 200, hide: 100 }}
													overlay={
														<Tooltip
															id="button-tooltip"
															className="custom-tooltip"
														>
															{selectedChat.participants[0].isRecrewter
																? t("descriptionCompanyProfile")
																: t("descriptionUserProfile")}
														</Tooltip>
													}
												>
													<button
														onClick={() => {
															if (selectedChat.participants[0].isRecrewter) {
																navigate(
																	"/company/cv/" +
																		selectedChat.participants[0].id
																);
															} else {
																navigate(
																	"/staff/cv/" + selectedChat.participants[0].id
																);
															}
														}}
													>
														{selectedChat.participants[0].isRecrewter
															? t("companyProfile")
															: t("userProfile")}
													</button>
												</OverlayTrigger>
											)}

										{/* Button to join meeting room */}
										{selectedChat.participants[0].connectionStatus ===
											ConnectionStatus.Accepted && (
											<OverlayTrigger
												placement="bottom"
												delay={{ show: 200, hide: 100 }}
												overlay={
													<Tooltip
														id="button-tooltip"
														className="custom-tooltip"
													>
														{t("joinMeetingRoom")}
													</Tooltip>
												}
											>
												<button
													onClick={async () => {
														const roomName = `digital-recrewter-${cyrb53Hash(
															selectedChat.id
														)}`;

														const kMeetDomain = "kmeet.infomaniak.com";
														const jitsiRoomLink = `https://${kMeetDomain}/${roomName}`;

														send(
															t("systemMessageJoinedMeetingRoom", {
																name: profileNameWrapper(myId!),
															})
														);

														window.open(jitsiRoomLink, "_blank");
													}}
												>
													<IconButton title="Video">
														<img src={images.Video} alt="Video" />
													</IconButton>
												</button>
											</OverlayTrigger>
										)}

										{selectedChat.participants[0].isRecrewter &&
											selectedChat.participants[0].companyName !==
												"Administrator" &&
											selectedChat.participants[0].connectionStatus !==
												ConnectionStatus.None && (
												<>
													{/* Button to accept incoming request from recrewter */}
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 200, hide: 100 }}
														overlay={
															<Tooltip
																id="button-tooltip"
																className="custom-tooltip"
															>
																<>{t("descriptionAcceptConnection")}</>
															</Tooltip>
														}
													>
														<button
															className="btn btn-success mx-2"
															disabled={
																selectedChat.participants[0]
																	.connectionStatus ===
																ConnectionStatus.Accepted
															}
															onClick={async () => {
																await respondUserConnectionAPI(
																	selectedChat.participants[0].id,
																	ConnectionStatus.Accepted
																);
																selectedChat.participants[0].connectionStatus =
																	ConnectionStatus.Accepted;
																setChats([...chats]);
															}}
														>
															{/* when connection accepted display text in button otherwise show request connection */}
															{selectedChat.participants[0].connectionStatus ===
															ConnectionStatus.Accepted
																? t("connectionAccepted")
																: t("acceptConnection")}
														</button>
													</OverlayTrigger>

													{/* Button to decline incoming request from recrewter */}
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 200, hide: 100 }}
														overlay={
															<Tooltip
																id="button-tooltip"
																className="custom-tooltip"
															>
																<>{t("descriptionDeclineConnection")}</>
															</Tooltip>
														}
													>
														<button
															className="btn btn-danger mx-2"
															disabled={
																selectedChat.participants[0]
																	.connectionStatus ===
																ConnectionStatus.Declined
															}
															onClick={async () => {
																await respondUserConnectionAPI(
																	selectedChat.participants[0].id,
																	ConnectionStatus.Declined
																);
																selectedChat.participants[0].connectionStatus =
																	ConnectionStatus.Declined;
																setChats([...chats]);
															}}
														>
															{/* when connection declined display text in button otherwise show decline connection */}
															{selectedChat.participants[0].connectionStatus ===
															ConnectionStatus.Declined
																? t("connectionDeclined")
																: t("declineConnection")}
														</button>
													</OverlayTrigger>
												</>
											)}
									</div>
								</div>

								{/* Chat Messages */}
								<div className="chat-messages" ref={chatContainerRef}>
									{selectedChat.messages
										.sort((a, b) => a.date.getTime() - b.date.getTime())
										.map((message, index) => {
											const isSender = message.senderId === myId;
											return (
												<div
													className={`message ${isSender ? "right" : "left"}`}
													key={index}
												>
													<div
														className={`message-row ${isSender ? "right" : ""}`}
													>
														{isSender ? (
															<>
																<div className="message-content">
																	<p>{message.content}</p>
																</div>
																{/* Profile image or trash icon */}
																{(index !== selectedChat.messages.length - 1 ||
																	message.isRead) && (
																	<img
																		className="profile-image"
																		src={myProfilePicture!}
																		alt="profile"
																	/>
																)}
																{index === selectedChat.messages.length - 1 &&
																	!message.isRead && (
																		<IconButton
																			title="Trash"
																			onClick={() => undoMyLastMessage()}
																		>
																			<img src={images.Trash} alt="Trash" />
																		</IconButton>
																	)}
															</>
														) : (
															<>
																<img
																	className="profile-image"
																	src={profilePhotoWrapper(message.senderId)!}
																	alt="profile"
																/>
																<div className="message-content">
																	<p>{message.content}</p>
																</div>
															</>
														)}
													</div>
													<p className="date">
														{message.date.toLocaleString()}
													</p>
												</div>
											);
										})}
								</div>

								{/* Input Field and Send Button */}
								<div className="input-prompt">
									<textarea
										placeholder={t("chat.placeholderTypeMessage")}
										value={messageField}
										disabled={!selectedChat}
										onChange={(e) => setMessageField(e.target.value)}
									></textarea>
									<button
										disabled={messageField.length === 0 || !selectedChat}
										onClick={() => send()}
									>
										{t("btnSend")}
									</button>
								</div>
							</>
						) : (
							<h3>{t("chat.notSelected")}</h3>
						)}
					</div>
				</>
			) : (
				<div>
					<h3>{t("chat.noMessages")}</h3>
				</div>
			)}
		</div>
	);
}
