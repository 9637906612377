// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IconButton, Link as MaterialLink } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import images from "../../assets/images";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import { checkRole } from "../../core/claimFunctions";
import AuthenticationContext from "../auth/AuthenticationContext";
import { useTranslation } from "react-i18next";
import WebSocketContext from "../../contexts/WebSocketContext";
import useFeedback from "../../utils/useFeedback";
import { getUnreadMessageCountAPI } from "../../core/apiFunctions";

type NavIconProps = {
	to: string;
	title: string;
	img: string;
	activeImg: string;
	alt: string;
	unreadMessageCount?: number;
};

const Sidebar = () => {
	const { claims } = useContext(AuthenticationContext);
	const { t } = useTranslation();

	const [unreadMessageCount, setUnreadMessageCount] = useState(0);
	const { messageReceivedSignalR } = useContext(WebSocketContext)!;
	const { showSuccess } = useFeedback();
	const location = useLocation();

	useEffect(() => {
		if (!location.pathname.endsWith("/chat")) {
			getUnreadMessageCountAPI()
				.then((response) => {
					const newUnreadMessageCount = response.data;
					if (newUnreadMessageCount > unreadMessageCount) {
						const messageKey =
							newUnreadMessageCount === 1 ? "newMessage" : "newMessages";
						showSuccess(t(messageKey, { count: newUnreadMessageCount }));
					}
					setUnreadMessageCount(newUnreadMessageCount);
				})
				.catch((error) => {
					console.error("Error fetching unread message count:", error);
				});
		}
	}, [messageReceivedSignalR]);

	const handleSidebarToggle = () => {
		document.body.classList.toggle("toggle-sidebar");
	};

	const NavIcon = (props: NavIconProps) => {
		return (
			<li>
				<NavLink to={props.to} title={props.title}>
					<img src={props.img} alt={props.alt} />
					<img src={props.activeImg} className="active" alt={props.alt} />
					<span>{props.title}</span>
					{props.unreadMessageCount && props.unreadMessageCount > 0 ? (
						<span className="unread-count">{props.unreadMessageCount}</span>
					) : null}
				</NavLink>
			</li>
		);
	};

	return (
		<>
			{/* aside start */}
			<aside>
				{/* top start */}
				<div className="top">
					<Link to="/dashboard" title="reCREWter">
						<img src={images.Logo} alt="reCREWter" />
					</Link>
					<IconButton title="Menu" onClick={handleSidebarToggle}>
						<img src={images.Hamburger} alt="Hamburger" />
					</IconButton>
				</div>
				{/* top end */}
				<div className="main-content">
					<ul>
						<NavIcon
							activeImg={images.DashboardActive}
							alt={t("dashboard")}
							img={images.Dashboard}
							title={t("dashboard")}
							to="/dashboard"
						/>
						<NavIcon
							title={t("myProfile")}
							to="/profile"
							img={images.MyProfile}
							activeImg={images.MyProfileActive}
							alt={t("myProfile")}
						/>
						{checkRole(claims, UserRoles.Recrewter) && (
							<>
								<NavIcon
									title={t("menuCompany")}
									to="/company"
									img={images.MyCompany}
									activeImg={images.MyCompanyActive}
									alt={t("menuCompany")}
								/>
								<NavIcon
									title={t("menuStaff")}
									to="/staff"
									img={images.Staff}
									activeImg={images.StaffActive}
									alt={t("menuStaff")}
								/>
							</>
						)}
						{checkRole(claims, UserRoles.Employee) && (
							<NavIcon
								title={t("previewProfileMenu")}
								to="/cv"
								img={images.MyCV}
								activeImg={images.MyCVActive}
								alt={t("previewProfileMenu")}
							/>
						)}
						<NavIcon
							title={t("jobs")}
							to="/jobs"
							img={images.Jobs}
							activeImg={images.JobsActive}
							alt={t("jobs")}
						/>
						<NavIcon
							title={t("menuChat")}
							to="/chat"
							img={images.Message}
							activeImg={images.MessageActive}
							alt={t("menuChat")}
							unreadMessageCount={unreadMessageCount}
						/>
					</ul>
					{/* bottom start */}
					<div className="bottom">
						{/* <div className="profile-complition">
							<div className="shadow"></div>
							<div className="content">
								<img
									src={images.AbstractCircle}
									className="abstract-circle"
									alt="Abstract Circle"
								/>
								<img
									src={images.AbstractDots}
									className="abstract-dots"
									alt="Abstract Dots"
								/>
								<p>75%</p>
								<MaterialLink href="#" title="Digital reCREWter Profile">
									<span>Digital reCREWter Profile</span>
									<img src={images.RightArrow} alt="Right Arrow" />
								</MaterialLink>
							</div>
						</div> */}
						<strong>{t("companyTitle")}</strong>
						<span>{t("copyright")}</span>
					</div>
					{/* bottom end */}
				</div>
			</aside>
			{/* aside end */}
			<div className="overlay" onClick={handleSidebarToggle}></div>
		</>
	);
};

export default Sidebar;
