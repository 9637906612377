import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { toggleNotificationsAPI } from "../../core/apiFunctions";
import ModalContent from "../../utils/ModalContent";

type Props = {
	isOpen: boolean;
	handleClose: () => void;
	isEnabled: boolean;
	setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationSetting: React.FC<Props> = ({
	isOpen,
	handleClose,
	isEnabled,
	setIsEnabled,
}) => {
	const { t } = useTranslation();

	const toggleNotification = () => {
		setIsEnabled(!isEnabled);
		toggleNotificationsAPI().catch(() => setIsEnabled(isEnabled));
	};

	return (
		<>
			<ModalContent
				title={t("notifications")}
				size="xs"
				show={isOpen}
				onClose={handleClose}
			>
				<div style={{ padding: "8px" }}>
					<FormControlLabel
						control={<Switch checked={isEnabled} />}
						label={
							isEnabled
								? t("notifications.enabled")
								: t("notifications.disabled")
						}
						onChange={toggleNotification}
					/>
				</div>
			</ModalContent>
		</>
	);
};

export default NotificationSetting;
