import {
	Button,
	ButtonGroup,
	Container,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import images from "../../assets/images";
import { RegisterChatBot } from "../../components/registerChatBot/RegisterChatBot";
import { FeedbackMode } from "../../config/Types/GeneralEnumDefinitions";
import { ssoURL } from "../../config/endpoints";
import useFeedback from "../../utils/useFeedback";
import i18n from "../../translations/i18n";

const Register = () => {
	const { showError } = useFeedback();

	enum Roles {
		None = 0,
		Recrewter = 1,
		Employee = 2,
	}
	const [showChat, setShowChat] = useState<boolean>(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
	const [selectedRole, setSelectedRole] = useState<Roles>(Roles.None);
	const [expanded, setExpanded] = React.useState(false);

	const { t } = useTranslation();

	const handleSSOLogin = (provider: string) => {
		const returnUrl = encodeURIComponent(`${window.location.origin}/sso`);
		window.location.href = `${ssoURL}/${provider}?returnUrl=${returnUrl}`;
	};
	useEffect(() => {
		i18n.changeLanguage(navigator.language.includes("de") ? "de" : "en");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// chatbot logic start

	const handleRegister = () => {
		if (selectedRole !== Roles.None) {
			setShowChat(true);
			setCurrentQuestionIndex(0);
			setExpanded(true);
		} else {
			showError(t("missingRole"), FeedbackMode.Error, 5000);
		}
	};
	// chatbot logic end

	return (
		<>
			<Helmet>
				<title>
					{t("register")} | {t("reCREWter")}
				</title>
			</Helmet>
			<div className="auth-wrapper">
				<Container maxWidth="lg">
					<div className="inner">
						<div className="left">
							<div className="content">
								<img
									src={images.Ellipse1}
									className="ellipse-one"
									alt="Blur Background"
								/>
								<img
									src={images.Ellipse2}
									className="ellipse-two"
									alt="Blur Background"
								/>
								<img src={images.Logo} className="logo" alt="reCREWter" />
								<h3>{t("getStartedNow")}</h3>
								<span>
									{t("ifUHaveAnAccInfo")}{" "}
									<Link to="/login">{t("loginHere")}!</Link>
								</span>
							</div>
							<img src={images.Illustration} alt="Illustration" />
						</div>
						<div className="right">
							<span>{t("iAmA")}…</span>
							<ToggleButtonGroup
								value={selectedRole}
								exclusive
								onChange={(e, newAlignment) =>
									setSelectedRole(newAlignment ?? Roles.None)
								}
								aria-label="text alignment"
							>
								<ToggleButton
									value={Roles.Employee}
									title={t("professional")}
									disabled={showChat}
								>
									<img src={images.Professional} alt={t("professional")} />
									<span>{t("professional")}</span>
								</ToggleButton>
								<ToggleButton
									value={Roles.Recrewter}
									title={t("company")}
									disabled={showChat}
								>
									<img src={images.Company} alt={t("company")} />
									<span>{t("company")}</span>
								</ToggleButton>
							</ToggleButtonGroup>
							<Button
								variant="contained"
								onClick={handleRegister}
								disabled={showChat}
							>
								{t("register")}
							</Button>
							<div className="separator">
								<div />
								<span>{t("orContinueWith")}</span>
								<div />
							</div>
							<ButtonGroup
								variant="outlined"
								color="inherit"
								aria-label="Basic button group"
							>
								<Button
									type="button"
									onClick={() => handleSSOLogin("linkedin")}
									disabled={showChat}
									className={showChat ? "register-sso-btn-disabled" : ""}
								>
									<img
										src={images.Linkedin}
										className="linkedin-sso"
										alt="Linkedin"
									/>
								</Button>
								<Button
									onClick={() => handleSSOLogin("google")}
									disabled={showChat}
									className={showChat ? "register-sso-btn-disabled" : ""}
								>
									<img src={images.Google} alt="Google" />
								</Button>
							</ButtonGroup>
						</div>
					</div>
				</Container>

				{/* chat bot start */}
				<RegisterChatBot
					selectedRole={selectedRole}
					setSelectedRole={setSelectedRole}
					currentQuestionIndex={currentQuestionIndex}
					setCurrentQuestionIndex={setCurrentQuestionIndex}
					showChat={showChat}
					setShowChat={setShowChat}
					expanded={expanded}
					setExpanded={setExpanded}
				/>
				{/* chat bot end */}
			</div>
		</>
	);
};

export default Register;
