import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { companyDetailsDTO } from "../../core/dto/user.models";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import { Link } from "@mui/material";

interface props {
	companyDetails: companyDetailsDTO | undefined;
}

export const ViewCompany = ({ companyDetails }: props) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="basic-information card" id="basicInformation">
				<h6>{t("companyInformation")}</h6>
				<Grid container spacing={{ xs: 4 }}>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("companyName")}:</span>
							<p>{companyDetails?.name}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("companyAddress")}:</span>
							<p>{companyDetails?.address}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("postcode")}:</span>
							<p>{companyDetails?.postcode}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("city")}:</span>
							<p>{companyDetails?.city}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("country")}:</span>
							<p>
								{companyDetails?.country === ""
									? "-"
									: t(enumToString(Country, companyDetails?.country))}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>
								{companyDetails?.country === Country.Switzerland
									? t("canton")
									: t("state")}
								:
							</span>
							<p>{t(enumToString(State, companyDetails?.state) || "-")}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("uid")}:</span>
							<p>{companyDetails?.uid === "" ? "-" : companyDetails?.uid}</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("eMailCompany")}:</span>
							<p>
								{companyDetails?.email ? (
									<Link href={`mailto:${companyDetails.email}`} target="_blank">
										{companyDetails.email}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("companyPhone")}:</span>
							<p>{companyDetails?.phoneNumber}</p>
						</div>
					</Grid>
				</Grid>
			</div>
			<div className="job-description card" id="myDreamJob">
				<h6>{t("description")}</h6>
				<p>{companyDetails?.description}</p>
			</div>
			<div className="basic-information card" id="basicInformation">
				<h6>{t("socialInformation")}</h6>
				<Grid container spacing={{ xs: 4 }}>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("website")}:</span>
							<p>
								{companyDetails?.website ? (
									<Link
										target="_blank"
										href={formatUrlLink(companyDetails?.website)}
									>
										{companyDetails.website}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("linkedin")}:</span>
							<p>
								{companyDetails?.linkedIn ? (
									<Link
										target="_blank"
										href={formatUrlLink(companyDetails?.linkedIn)}
									>
										{companyDetails.linkedIn}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("xing")}: </span>
							<p>
								{companyDetails?.xing ? (
									<Link
										target="_blank"
										href={formatUrlLink(companyDetails?.xing)}
									>
										{companyDetails.xing}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
					<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
						<div className="info-detail">
							<span>{t("youtube")}:</span>
							<p>
								{companyDetails?.youTubeUrl ? (
									<Link
										target="_blank"
										href={formatUrlLink(companyDetails?.youTubeUrl)}
									>
										{companyDetails.youTubeUrl}
									</Link>
								) : (
									"-"
								)}
							</p>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};
