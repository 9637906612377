import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import common_en from "./en.json";
import common_de from "./de.json";
import enum_medical_jobs_en from "./enum medical jobs/en_enum_medical_jobs.json";
import enum_medical_jobs_de from "./enum medical jobs/de_enum_medical_jobs.json";
import enum_userdata_en from "./enum userdata/en_enum_userdata.json";
import enum_userdata_de from "./enum userdata/de_enum_userdata.json";
import terms_en from "./general terms and conditions/en_terms.json";
import terms_de from "./general terms and conditions/de_terms.json";
import imprint_en from "./imprint/en_imprint.json";
import imprint_de from "./imprint/de_imprint.json";
import privacy_en from "./privacy policy/en_privacy.json";
import privacy_de from "./privacy policy/de_privacy.json";
import fulluserpage_en from "./fulluserpage/en_fulluserpage.json";
import fulluserpage_de from "./fulluserpage/de_fulluserpage.json";
import login_register_en from "./login register/en_login_register.json";
import login_register_de from "./login register/de_login_register.json";
import static_en from "./static pages/en_static.json";
import static_de from "./static pages/de_static.json";
import chat_en from "./chat/en_chat.json";
import chat_de from "./chat/de_chat.json";
import joboffer_en from "./joboffer/en_joboffer.json";
import joboffer_de from "./joboffer/de_joboffer.json";
import support_en from "./support/en_support.json";
import support_de from "./support/de_support.json";
import country_en from "./country/en_country.json";
import country_de from "./country/de_country.json";
import state_de from "./state/de_state.json";
import state_en from "./state/en_state.json";

const resources = {
	en: {
		translation: {
			...common_en,
			...enum_medical_jobs_en,
			...terms_en,
			...imprint_en,
			...privacy_en,
			...enum_userdata_en,
			...static_en,
			...fulluserpage_en,
			...login_register_en,
			...chat_en,
			...joboffer_en,
			...support_en,
			...country_en,
			...state_en,
		},
	},
	de: {
		translation: {
			...common_de,
			...enum_medical_jobs_de,
			...terms_de,
			...imprint_de,
			...privacy_de,
			...enum_userdata_de,
			...static_de,
			...fulluserpage_de,
			...login_register_de,
			...chat_de,
			...joboffer_de,
			...support_de,
			...country_de,
			...state_de,
		},
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // Passes i18n instance to react-i18next
	.init({
		resources,
		// lng: "en",
		fallbackLng: "en",
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
