import { useState, useEffect } from "react";

export function isGuid(str: string) {
	const guidRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
	return guidRegex.test(str);
}

export function convertDateTimeToDate(dateTime: string) {
	return dateTime.split("T")[0];
}

export function isObjectEmpty(obj: any) {
	return Object.keys(obj).length <= 0;
}

export function hasEmptyProperties(obj: any) {
	for (const key in obj) {
		if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
			return true;
		}
	}
	return false;
}

export function useDebounce(value: any, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export function downloadFile(url: string, fileName: string) {
	// create "a" HTLM element with href to file & click
	const link = document.createElement("a");
	link.href = url;
	link.download = fileName;
	document.body.appendChild(link);
	// preventdefault with click
	link.click();

	// clean up "a" element & remove ObjectURL
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
}

export function downloadUseStateToJson(object: any, name: string) {
	const file = new Blob([JSON.stringify(object)], { type: "application/json" });
	const fileURL = URL.createObjectURL(file);
	downloadFile(fileURL, name + ".json");
}

//fast and simple hash function with bitwise operations (non-cryptographic!)
export function cyrb53Hash(str: string, seed: number = 0) {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

	return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}
