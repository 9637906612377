import { ReactElement, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import { isCompanyProfileCompletedAPI } from "../../core/apiFunctions";
import {
	checkLoggedInStatus,
	checkRoles,
	checkUserAuthorized,
} from "../../core/claimFunctions";
import { getClaims } from "../../core/handleJWT";

export default function AuthorizedExtended(props: authorizedProps) {
	const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
	const [needsToLogIn, setNeedsToLogIn] = useState<boolean | null>(null);
	const [isProfileComplete, setIsProfileComplete] = useState<boolean | null>(
		null
	);
	const claims = useMemo(() => getClaims(), []);

	const navigate = useNavigate();

	useEffect(() => {
		const checkProfileCompletion = async () => {
			const updatedClaims = getClaims();
			const isloggedIn = checkLoggedInStatus(updatedClaims);

			if (isloggedIn) {
				setNeedsToLogIn(false);

				const isRoleCorrect = checkRoles(updatedClaims, props.roles);
				const isUserAuthorized = checkUserAuthorized(updatedClaims);

				if (isRoleCorrect && isUserAuthorized) {
					try {
						let companyProfileComplete = true;
						if (checkRoles(updatedClaims, [UserRoles.Recrewter])) {
							companyProfileComplete = (await isCompanyProfileCompletedAPI())
								.data;
							if (companyProfileComplete === false) {
								navigate("/company");
							}
						}

						setIsProfileComplete(companyProfileComplete);
						setIsAuthorized(isUserAuthorized);
					} catch (error) {
						setIsAuthorized(false);
					}
				} else {
					setIsAuthorized(false);
					setIsProfileComplete(false);
					if (!isUserAuthorized) {
						navigate("/profile");
					}
				}
			} else {
				setNeedsToLogIn(true);
				setIsAuthorized(false);
				setIsProfileComplete(false);
			}
		};

		checkProfileCompletion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [claims, navigate]);

	if (
		isAuthorized === null ||
		isProfileComplete === null ||
		needsToLogIn === null
	) {
		return null;
	}

	return (
		<>
			{isAuthorized && isProfileComplete && !needsToLogIn
				? props.authorized
				: needsToLogIn
					? props.relogIn
					: props.notAuthorized}
		</>
	);
}

interface authorizedProps {
	authorized: ReactElement;
	notAuthorized: ReactElement;
	relogIn?: ReactElement;
	roles: UserRoles[];
}
