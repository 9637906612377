import { Grid2 as Grid } from "@mui/material";
import { employeeSearchDTO } from "../../core/dto/dto.models";
import { SearchResultCard } from "./SearchResultCard/SearchResultCard";

export default function SearchResults({
	results,
	handleStarClick,
	isFavorite,
}: {
	results?: employeeSearchDTO[];
	handleStarClick: (employee: employeeSearchDTO, userId: string) => void;
	isFavorite: Record<string, string[]>;
}) {
	return (
		<>
			<Grid
				container
				spacing={{ xs: 3, sm: 4, lg: 5 }}
				className={"searchResult-container"}
			>
				{results?.map((employee, index) => (
					<Grid size={{ sm: 6, md: 4, xs: 12 }} key={`searchResults-${index}`}>
						<SearchResultCard
							isFavorite={isFavorite}
							employee={employee}
							handleStarClick={handleStarClick}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
}
