import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DisplayErrors(props: displayErrorProps) {
	const { t } = useTranslation();

	return (
		<>
			{props.errors && props.errors.length > 0 ? (
				<Alert severity="error" variant="outlined" className="displayAlert">
					{props.errors.map((error: any, index: number) => {
						if (error.includes(" ")) {
							return <span key={index}>{error}</span>;
						} else {
							return <span key={index}>{t(error)}</span>;
						}
					})}
				</Alert>
			) : null}
		</>
	);
}

interface displayErrorProps {
	errors?: string[];
}
