import Grid from "@mui/material/Grid2";
import { useContext, useEffect, useState } from "react";
import images from "../../assets/images";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import {
	dashboardCountsAPI,
	getUnreadMessageCountAPI,
} from "../../core/apiFunctions";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import { checkRole } from "../../core/claimFunctions";
import { useLoading } from "../../contexts/LoadingContext";
import WebSocketContext from "../../contexts/WebSocketContext";
import useFeedback from "../../utils/useFeedback";

const Dashboard = () => {
	const [dashboardCounts, setDashboardCounts] = useState({
		unreadMessageCount: 0,
		externalJobCount: 0,
		suggestionsCount: 0,
	});
	const { t } = useTranslation();
	const { claims } = useContext(AuthenticationContext);
	const navigate = useNavigate();
	const { messageReceivedSignalR } = useContext(WebSocketContext)!;
	const { showSuccess } = useFeedback();
	const location = useLocation();
	const { setLoading } = useLoading();

	const isUserRoleRecrewter = checkRole(claims, UserRoles.Recrewter);

	// Initial fetch of dashboard counts
	useEffect(() => {
		setLoading(true);
		dashboardCountsAPI()
			.then((res) => {
				setDashboardCounts({
					unreadMessageCount:
						res && res.data.unreadMessageCount > 0
							? res.data.unreadMessageCount
							: 0,
					externalJobCount:
						res && res.data.externalJobCount > 0
							? res.data.externalJobCount
							: 0,
					suggestionsCount:
						res && res.data.suggestionsCount > 0
							? res.data.suggestionsCount
							: 0,
				});
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update unread message count when a new message is received
	useEffect(() => {
		if (messageReceivedSignalR) {
			if (!location.pathname.endsWith("/chat")) {
				getUnreadMessageCountAPI()
					.then((response) => {
						const newUnreadMessageCount = response.data;
						if (newUnreadMessageCount > dashboardCounts.unreadMessageCount) {
							const messageKey =
								newUnreadMessageCount === 1 ? "newMessage" : "newMessages";
							showSuccess(t(messageKey, { count: newUnreadMessageCount }));
						}
						setDashboardCounts((prevCounts) => ({
							...prevCounts,
							unreadMessageCount: newUnreadMessageCount,
						}));
					})
					.catch((error) => {
						console.error("Error fetching unread message count:", error);
					});
			}
		}
	}, [messageReceivedSignalR]);

	return (
		<>
			<Helmet>
				<title>
					{t("dashboard")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* inner-wrapper start */}
					<div className="inner-wrapper">
						<Grid container spacing={{ xs: 3, md: 4, xxl: 7 }}>
							{isUserRoleRecrewter && (
								<Grid size={{ sm: 6, xs: 12 }}>
									<div className="stats" onClick={() => navigate("/staff")}>
										<div className="icon">
											<img src={images.JobMatching} alt="Job Matchings" />
										</div>
										<div className="content">
											<span>{t("mySuggestions")}</span>
											<p>
												{dashboardCounts.suggestionsCount
													?.toString()
													?.padStart(2, "0")}
											</p>
										</div>
									</div>
								</Grid>
							)}
							<Grid size={{ sm: isUserRoleRecrewter ? 6 : 12, xs: 12 }}>
								<div className="stats" onClick={() => navigate("/chat")}>
									<div className="icon">
										<img src={images.NewChats} alt={t("unreadMessage")} />
									</div>
									<div className="content">
										<span>{t("unreadMessage")}</span>
										<p>
											{dashboardCounts.unreadMessageCount
												?.toString()
												?.padStart(2, "0")}
										</p>
									</div>
								</div>
							</Grid>
							<Grid size={{ sm: 6, xs: 12 }}>
								<div className="stats" onClick={() => navigate("/jobs")}>
									<div className="icon">
										<img src={images.NewJobs} alt="Jobs" />
									</div>
									<div className="content">
										<span>{t("jobs")}</span>
										<p>
											{dashboardCounts.externalJobCount
												?.toString()
												?.padStart(2, "0")}
										</p>
									</div>
								</div>
							</Grid>
							<Grid size={{ sm: 6, xs: 12 }} className="my-profile-tile">
								<div className="stats" onClick={() => navigate("/profile")}>
									<div className="icon">
										<img src={images.MyProfile2} alt="Profile" />
									</div>
									<div className="content">
										<span>
											<span className="profileLink">{t("myProfile")}</span>
										</span>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
					{/* inner-wrapper end */}
				</main>
				{/* main end */}
			</div>
			{/* wrapper end */}
		</>
	);
};

export default Dashboard;
