import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/header/Header";
import {
	Availability,
	EducationLevel,
	EmploymentType,
	JobPosition,
	Salary,
	Title,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";

import { useContext, useEffect, useState } from "react";

import {
	employeeSearchFilterAPI,
	getBackgroundPhotoAPI,
	getEmployeeFromUserIdAPI,
	getProfilePhotoAPI,
	getRecrewterDetailsAPI,
} from "../../core/apiFunctions";
import {
	employeeSearchDTO,
	employeeSearchFilterDTO,
} from "../../core/dto/dto.models";
import {
	handleOnChange,
	listOfCountriesWithStates,
} from "../../core/helperConst";

import { getNameIdentifier } from "../../core/claimFunctions";

import images from "../../assets/images";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import SearchResults from "../../components/searchResults/SearchResults";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DropdownField from "../../components/common/DropdownField";
import { useLoading } from "../../contexts/LoadingContext";
const defaultImage = images.Talent;
export const Personal = () => {
	const { t } = useTranslation();
	const { claims } = useContext(AuthenticationContext);
	const currentRecrewter = getNameIdentifier(claims);

	const { setLoading } = useLoading();
	const getEmptyForm = () => {
		return {
			title: Title.None,
			country: Country.None,
			state: State.None,
			employmentType: EmploymentType.None,
			jobPosition: JobPosition.None,
			workload: Workload.None,
			salary: Salary.None,
			division: MedicalDivision.None,
			educationLevel: EducationLevel.None,
			availability: Availability.None,
			description: "",
		};
	};

	const [searchResults, setSearchResults] = useState<employeeSearchDTO[]>([]);
	const [topMatches, setTopMatches] = useState<employeeSearchDTO[]>([]);
	const [filterSettings, setFilterSettings] =
		useState<employeeSearchFilterDTO>(getEmptyForm());
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [favoriteCards, setFavoriteCards] = useState<employeeSearchDTO[]>([]);

	const [openTopMatches, setOpenTopMatches] = useState<boolean>(false);
	const [openOtherMatches, setOpenOtherMatches] = useState<boolean>(false);

	const [favorites, setFavorites] = useState<Record<string, string[]>>(() => {
		const storedFavorites = localStorage.getItem("favorites");
		return storedFavorites ? JSON.parse(storedFavorites) : {};
	});

	useEffect(() => {
		const storedFavorites = localStorage.getItem("favorites");
		if (storedFavorites) {
			setFavorites(JSON.parse(storedFavorites));
		}
	}, [currentRecrewter]);

	useEffect(() => {
		localStorage.setItem("favorites", JSON.stringify(favorites));
	}, [favorites, currentRecrewter]);

	useEffect(() => {
		const fetchRecrewterDetails = async () => {
			try {
				const response = await getRecrewterDetailsAPI();
				const idealCandidate = response.data.idealCandidate;

				if (idealCandidate) {
					setFilterSettings({
						title: Title.None,
						country: idealCandidate?.country || Country.None,
						state: idealCandidate?.state || State.None,
						employmentType:
							idealCandidate?.employmentType || EmploymentType.None,
						jobPosition: idealCandidate?.jobPosition || JobPosition.None,
						workload: idealCandidate?.workload || Workload.None,
						salary: idealCandidate?.salary || Salary.None,
						division: idealCandidate?.division || MedicalDivision.None,
						educationLevel:
							idealCandidate?.educationLevel || EducationLevel.None,
						availability: idealCandidate?.availability || Availability.None,
						description: "",
					});
				} else {
					setFilterSettings(getEmptyForm());
				}
			} catch (error) {
				console.error("Error fetching recrewter details:", error);
				setFilterSettings(getEmptyForm());
			}
		};

		window.scrollTo(0, 0);
		fetchRecrewterDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchFavoriteCardsFromBackend = async () => {
		try {
			const userIds = favorites[currentRecrewter] || [];
			const favoriteCardsFromBackend = await Promise.all(
				userIds.map(async (userId) => {
					try {
						const employeeData = await getEmployeeFromUserIdAPI(userId);
						const profilePhotoResponse = await getProfilePhotoAPI(userId);
						const backgroundPhotoResponse = await getBackgroundPhotoAPI(userId);

						employeeData.data.profilePicture = profilePhotoResponse
							? URL.createObjectURL(profilePhotoResponse.data)
							: defaultImage;

						employeeData.data.headerPicture = backgroundPhotoResponse
							? URL.createObjectURL(backgroundPhotoResponse.data)
							: "linear-gradient(45deg, rgb(83, 91, 235),rgb(76, 11, 174))";

						return employeeData;
					} catch (error) {
						return null;
					}
				})
			);
			setFavoriteCards(
				favoriteCardsFromBackend
					.filter(Boolean)
					.map((response) => response?.data)
			);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				await fetchFavoriteCardsFromBackend();
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favorites, currentRecrewter]);

	const onChange = handleOnChange(filterSettings, setFilterSettings);

	const submit = () => {
		setIsSubmitting(true);

		var topMatches = true;
		var employeeList: employeeSearchDTO[] = [];
		setLoading(true);
		employeeSearchFilterAPI(filterSettings, topMatches)
			.then(async (response) => {
				for (let employee of response.data) {
					// try {
					//   const blobProfile = await getProfilePhotoAPI(employee.userId)

					//   employee.profilePicture = blobProfile
					//     ? URL.createObjectURL(blobProfile.data)
					//     : defaultImage
					// } catch (error) {
					//   employee.profilePicture = defaultImage
					// }

					// try {
					//   const blobBackground = await getBackgroundPhotoAPI(employee.userId)
					//   employee.headerPicture = blobBackground
					//     ? URL.createObjectURL(blobBackground.data)
					//     : null // Use default styling if null
					// } catch (error) {
					//   // When there is no customized headerPicture (background), styling in "SearchResults.scss > .resultsCardHeader (background-image)" is used
					// }

					employeeList.push(employee);
				}
				setTopMatches(employeeList);

				setOpenTopMatches(employeeList.length > 0);
				setOpenOtherMatches(false);
			})
			.then(async () => {
				employeeSearchFilterAPI(filterSettings!, !topMatches).then(
					async (response) => {
						var otherEmployees: employeeSearchDTO[] = [];

						for (let employee of response.data) {
							if (!employeeList.some((x) => x.userId === employee.userId)) {
								// try {
								//   const blobProfile = await getProfilePhotoAPI(employee.userId)
								//   employee.profilePicture = blobProfile
								//     ? URL.createObjectURL(blobProfile.data)
								//     : defaultImage
								// } catch (error) {
								//   employee.profilePicture = defaultImage
								// }

								otherEmployees.push(employee);
							}
						}
						setSearchResults(otherEmployees);
						setIsSubmitting(false);

						setOpenOtherMatches(otherEmployees.length > 0);
					}
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleStarClickInParent = async (
		employee: employeeSearchDTO,
		userId: string
	) => {
		setFavoriteCards((prevFavorites) => {
			const userToAddTopMatches = topMatches.find(
				(user) => user.userId === userId
			);
			const userToAddSearchResults = searchResults.find(
				(user) => user.userId === userId
			);

			let updatedFavorites = [...prevFavorites];
			updatedFavorites = updatedFavorites.filter(
				(favUser) => favUser.userId !== userId
			);

			setFavorites((prev) => {
				const updatedStars = { ...prev };
				const currentRecrewterFavorites = favorites[currentRecrewter] || [];

				if (currentRecrewterFavorites.includes(userId)) {
					const filteredFavorites = currentRecrewterFavorites.filter(
						(id) => id !== userId
					);
					updatedStars[currentRecrewter] = filteredFavorites;
				} else {
					updatedStars[currentRecrewter] = [
						...currentRecrewterFavorites,
						userId,
					];

					if (userToAddTopMatches) {
						updatedFavorites.push(userToAddTopMatches);
					}

					if (
						userToAddSearchResults &&
						!topMatches.some((user) => user.userId === userId)
					) {
						updatedFavorites.push(userToAddSearchResults);
					}
				}
				return updatedStars;
			});
			return updatedFavorites;
		});
	};

	return (
		<div>
			<Helmet>
				<title>
					{t("menuStaff")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* inner-wrapper start */}
					<div className="inner-wrapper personal">
						<div className="dream-job card">
							<h6>{t("perfectAddition")}</h6>
							<span>{t("recruitEfficiently")}</span>
							<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerCountry"}
										field={"country"}
										selected={filterSettings?.country}
										onChange={onChange}
										enumType={Country}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={
											filterSettings?.country === Country.Switzerland
												? "headerCanton"
												: "headerState"
										}
										field={"state"}
										index={
											filterSettings?.country === Country.Germany
												? 8400
												: filterSettings?.country === Country.Austria
													? 1500
													: 21600 // Default for Switzerland
										}
										indexSize={
											filterSettings?.country === Country.Germany
												? 17
												: filterSettings?.country === Country.Austria
													? 10
													: 27 // Default for Switzerland (Canton)
										}
										selected={
											!listOfCountriesWithStates.includes(
												filterSettings?.country
											)
												? State.None
												: filterSettings?.state!
										}
										disabled={
											filterSettings?.country === Country.None ||
											!listOfCountriesWithStates.includes(
												filterSettings?.country!
											)
										}
										onChange={onChange}
										enumType={State}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerEmployementType"}
										field={"employmentType"}
										selected={filterSettings?.employmentType}
										onChange={onChange}
										enumType={EmploymentType}
									/>
								</Grid>

								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerPosition"}
										field={"jobPosition"}
										selected={filterSettings?.jobPosition}
										onChange={onChange}
										enumType={JobPosition}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerPensum"}
										field={"workload"}
										selected={filterSettings?.workload}
										onChange={onChange}
										enumType={Workload}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerSalary"}
										field={"salary"}
										selected={filterSettings?.salary}
										onChange={onChange}
										enumType={Salary}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerDivision"}
										field={"division"}
										selected={filterSettings?.division}
										onChange={onChange}
										enumType={MedicalDivision}
									/>
								</Grid>
								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerEducationLevel"}
										field={"educationLevel"}
										selected={filterSettings?.educationLevel}
										onChange={onChange}
										enumType={EducationLevel}
									/>
								</Grid>

								<Grid size={{ sm: 6, md: 4, xs: 12 }}>
									<DropdownField
										displayName={"headerAvailability"}
										field={"availability"}
										selected={filterSettings?.availability}
										onChange={onChange}
										enumType={Availability}
									/>
								</Grid>
								<Grid size={{ sm: 12, md: 12, xs: 12 }} className="btn-group">
									<Button
										variant="outlined"
										onClick={() => setFilterSettings(getEmptyForm())}
									>
										{t("reset")}
									</Button>
									<Button
										variant="contained"
										onClick={submit}
										disabled={isSubmitting}
									>
										{t("menuSearch")}
									</Button>
								</Grid>
							</Grid>
						</div>

						<h6>{t("topMatches")}</h6>

						<Accordion
							expanded={openTopMatches}
							onChange={() => setOpenTopMatches((prev) => !prev)}
						>
							<AccordionSummary
								expandIcon={<img src={images.DownArrow} alt="downArrow" />}
								aria-controls="panel1-content"
								id="panel1-header"
							>
								<Typography>{t("topMatches")}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{topMatches.length !== 0 ? (
									<SearchResults
										results={topMatches}
										handleStarClick={handleStarClickInParent}
										isFavorite={favorites}
									/>
								) : (
									<Typography variant="body1" className="noData">
										{t("noMatches")}
									</Typography>
								)}
							</AccordionDetails>
						</Accordion>

						<Accordion
							expanded={openOtherMatches}
							onChange={() => setOpenOtherMatches((prev) => !prev)}
						>
							<AccordionSummary
								expandIcon={<img src={images.DownArrow} alt="downArrow" />}
								aria-controls="panel2-content"
								id="panel2-header"
							>
								<Typography>{t("otherMatches")}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{searchResults.length === 0 ? (
									<Typography variant="body1" className="noData">
										{t("noOtherMatches")}
									</Typography>
								) : (
									<SearchResults
										results={searchResults}
										handleStarClick={handleStarClickInParent}
										isFavorite={favorites}
									/>
								)}
							</AccordionDetails>
						</Accordion>

						<Accordion>
							<AccordionSummary
								expandIcon={<img src={images.DownArrow} alt="downArrow" />}
								aria-controls="panel3-content"
								id="panel3-header"
							>
								<Typography>{t("listFavourites")} </Typography>
								<img src={images.StarActive} alt="star" />
							</AccordionSummary>
							<AccordionDetails>
								{favoriteCards.length !== 0 ? (
									<SearchResults
										results={favoriteCards}
										handleStarClick={handleStarClickInParent}
										isFavorite={favorites}
									/>
								) : (
									<Typography variant="body1" className="noData">
										{t("noFavourites")}
									</Typography>
								)}
							</AccordionDetails>
						</Accordion>
					</div>
				</main>
			</div>
		</div>
	);
};
