const baseAPIURL = process.env.REACT_APP_API_URL;

export const urlChatSignalR = `${baseAPIURL}/chat-hub`;
export const urlChat = `${baseAPIURL}/chat`;

export const urlAccounts = `${baseAPIURL}/accounts`;
export const ssoURL = `${urlAccounts}/external-login`;

export const urlCompany = `${baseAPIURL}/company`;
export const urlEmployee = `${baseAPIURL}/employee`;
export const urlRecrewter = `${baseAPIURL}/recrewter`;
export const urlContact = `${baseAPIURL}/contact`;
export const urlJob = `${baseAPIURL}/job`;
export const urlSearch = `${baseAPIURL}/search`;
export const urlSubscibe = `${baseAPIURL}/subscribe`;
