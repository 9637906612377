import {
	Dialog,
	DialogContent,
	IconButton,
	Button,
	DialogTitle,
	styled,
	DialogActions,
} from "@mui/material";
import React from "react";
import images from "../assets/images";

interface Props {
	title?: string;
	size: "xs" | "sm" | "md" | "lg" | "xl" | undefined;
	children: any;
	show: boolean;
	onHideCallbackSecondary?: () => void;
	onHideCallbackPrimary?: () => void;
	onHideCallbackThird?: () => void;
	primaryButton?: string | null;
	secondaryButton?: string | null;
	secondaryButtonColor?:
		| "inherit"
		| "primary"
		| "secondary"
		| "success"
		| "error"
		| "info"
		| "warning";
	thirdButton?: string | null;
	onClose?: () => void;
}

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	"&.MuiDialogTitle-root": {
		fontSize: "24px",
		paddingBottom: "8px",
	},
}));

const ModalContent: React.FC<Props> = ({
	title,
	size,
	children,
	show,
	onHideCallbackPrimary,
	onHideCallbackSecondary,
	onHideCallbackThird,
	primaryButton,
	secondaryButton,
	secondaryButtonColor,
	thirdButton,
	onClose,
}) => {
	return (
		<Dialog
			open={show}
			onClose={onClose}
			maxWidth={size}
			fullWidth={size !== "xs"}
		>
			<StyledDialogTitle>
				<div>{title}</div>
			</StyledDialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				size="small"
				sx={(theme) => ({
					position: "absolute",
					right: 8,
					top: 8,
					color: theme.palette.grey[500],
					height: "auto",
					width: "auto",
					padding: "10px",
					borderRadius: "50%",
					margin: "0px 8px 0 0",
				})}
			>
				<img
					src={images.CloseLarge}
					alt="Close"
					style={{ height: "28px", width: "28px" }}
				/>
			</IconButton>

			<DialogContent sx={{ p: 3, pt: 2 }}>{children}</DialogContent>
			{(primaryButton || secondaryButton || thirdButton) && (
				<DialogActions sx={{ p: 0 }}>
					<div
						className="modal-footer"
						style={{ display: "flex", gap: "16px", padding: "0 24px 24px 0" }}
					>
						{primaryButton && (
							<Button
								variant="contained"
								onClick={() => {
									onHideCallbackPrimary && onHideCallbackPrimary();
								}}
								title={primaryButton}
							>
								{primaryButton}
							</Button>
						)}
						{secondaryButton && (
							<Button
								variant={thirdButton ? "contained" : "outlined"}
								onClick={() => {
									onHideCallbackSecondary && onHideCallbackSecondary();
								}}
								color={secondaryButtonColor || "inherit"}
								title={secondaryButton}
							>
								{secondaryButton}
							</Button>
						)}
						{thirdButton && (
							<Button
								variant="outlined"
								onClick={() => {
									onHideCallbackThird && onHideCallbackThird();
								}}
								title={thirdButton}
							>
								{thirdButton}
							</Button>
						)}
					</div>
				</DialogActions>
			)}
		</Dialog>
	);
};

export default ModalContent;
