import { SetStateAction, useContext } from "react";
import { FeedbackMode } from "../config/Types/GeneralEnumDefinitions";
import AuthenticationContext from "../components/auth/AuthenticationContext";

const useFeedback = () => {
	const { setMessage, setMode, setDuration, setSuccess } = useContext(
		AuthenticationContext
	);

	function showSuccess(
		message: SetStateAction<string>,
		mode = FeedbackMode.Success,
		duration = 5000
	) {
		setMessage(message);
		setMode(mode);
		setDuration(duration);
		setSuccess(true);
	}

	function showError(
		message: SetStateAction<string>,
		mode = FeedbackMode.Error,
		duration = 5000
	) {
		setMessage(message);
		setMode(mode);
		setDuration(duration);
		setSuccess(false);
	}

	function showCentral(
		message: SetStateAction<string>,
		mode = FeedbackMode.Info,
		duration = 5000
	) {
		setMessage(message);
		setMode(mode);
		setDuration(duration);
		setSuccess(true);
	}

	return { showSuccess, showError, showCentral };
};

export default useFeedback;
